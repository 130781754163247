import React, { useState, useEffect, useCallback } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { TextField } from '@refinedev/antd';
import { Button, Spin, Alert, Grid } from 'antd';
import Information from './Information';
import './styles.less';

import { CloseOutlined, ZoomInOutlined, ZoomOutOutlined, ReloadOutlined } from '@ant-design/icons';

interface DefectProps {
    color: string;
    title: string;
    amount: number;
}

interface CompareSliderModalProps {
    itemOne: string | undefined;
    itemTwo: string | undefined;
    fileName: string;
    projectName: string;
    show: boolean;
    notFound: boolean;
    notFoundText: string;
    hideCloseButton?: boolean;
    defects: Array<{ defectId: number; amount: number }>;
    defectsMapping: Array<{
        id: number;
        name: string;
        frameColor: string;
        groupName: string;
    }>;
    onClose?: () => void;
}

const Defect: React.FC<DefectProps> = ({ color, title, amount }) => {
    const { useBreakpoint } = Grid;
    const { xxl } = useBreakpoint();

    return (
        <div className='defect'>
            <div
                className='defect__color'
                style={{
                    backgroundColor: color,
                }}
            ></div>
            <span className='defect__title' style={{ fontSize: xxl ? '18px' : '14px' }}>
                {title}
            </span>
            <span className='defect__amount' style={{ fontSize: xxl ? '18px' : '14px' }}>
                {amount}
            </span>
        </div>
    );
};

const CompareSliderModal: React.FC<CompareSliderModalProps> = ({
    itemOne,
    itemTwo,
    fileName,
    projectName,
    show,
    defects,
    hideCloseButton = false,
    notFound = false,
    notFoundText,
    defectsMapping,
    onClose: closeHandler,
}) => {
    const [loadingOne, setLoadingOne] = useState<boolean>(true);
    const [loadingTwo, setLoadingTwo] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [disablePadding, setDisablePadding] = useState<boolean>(false);

    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    const isLoading = useCallback(() => {
        if (itemTwo) {
            return loadingOne || loadingTwo;
        }
        return loadingOne;
    }, [loadingOne, loadingTwo, itemTwo]);

    const resetState = () => {
        setLoadingOne(true);
        setLoadingTwo(true);
        setError(false);
    };

    const renderMainContent = () => {
        if (error) {
            return (
                <Alert
                    message='Error'
                    description='An error occurred while loading the image. Try later.'
                    type='error'
                    showIcon
                />
            );
        }
        return (
            <>
                <div className='transform-wrapper'>
                    <TransformWrapper
                        doubleClick={{ disabled: true }}
                        initialScale={1}
                        panning={{
                            disabled: disablePadding,
                        }}
                    >
                        {({ zoomIn, zoomOut, resetTransform }) => (
                            <>
                                <TransformComponent>
                                    {itemTwo ? (
                                        <ReactCompareSlider
                                            onlyHandleDraggable
                                            position={0}
                                            itemOne={
                                                <ReactCompareSliderImage
                                                    src={itemOne}
                                                    alt='Image one'
                                                    style={{
                                                        maxHeight: '72.5vh',
                                                        objectFit: 'fill',
                                                    }}
                                                />
                                            }
                                            itemTwo={
                                                <ReactCompareSliderImage
                                                    src={itemTwo}
                                                    alt='Image two'
                                                    style={{
                                                        maxHeight: '72.5vh',
                                                        objectFit: 'fill',
                                                    }}
                                                />
                                            }
                                            style={{
                                                width: vw * 0.8,
                                            }}
                                        />
                                    ) : (
                                        <img
                                            src={itemOne}
                                            width={vw * 0.8}
                                            height='auto'
                                            alt='panel screen'
                                        />
                                    )}
                                </TransformComponent>
                                <div className='button-wrapper'>
                                    <Button
                                        size='large'
                                        shape='circle'
                                        onClick={() => zoomIn()}
                                        type='default'
                                    >
                                        <ZoomInOutlined />
                                    </Button>
                                    <Button
                                        size='large'
                                        shape='circle'
                                        onClick={() => zoomOut()}
                                        type='default'
                                    >
                                        <ZoomOutOutlined />
                                    </Button>
                                    <Button
                                        size='large'
                                        shape='circle'
                                        onClick={() => resetTransform()}
                                        type='default'
                                    >
                                        <ReloadOutlined />
                                    </Button>
                                </div>
                            </>
                        )}
                    </TransformWrapper>
                </div>
                {itemTwo && (
                    <div
                        className='defects-wrapper'
                        style={{
                            width: vw * 0.8,
                            justifyContent: defects.length > 5 ? 'flex-start' : 'center',
                        }}
                    >
                        {defects.map((el: { defectId: number; amount: number }) => (
                            <Defect
                                key={el.defectId}
                                color={
                                    defectsMapping.find((item) => item.id === el.defectId)
                                        ?.frameColor || '#000'
                                }
                                title={
                                    defectsMapping.find((item) => item.id === el.defectId)?.name ||
                                    'Default title'
                                }
                                amount={el.amount}
                            />
                        ))}
                    </div>
                )}
            </>
        );
    };

    useEffect(() => {
        // exit from component by Esc
        const keyDownHandler = (event: { key: string; preventDefault: () => void }) => {
            if (event.key === 'Escape') {
                event.preventDefault();

                if (closeHandler) closeHandler();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [closeHandler]);

    useEffect(() => {
        // contoling img loading
        if (show) {
            if (itemOne) {
                let imgOne = document.createElement('img');
                imgOne.src = itemOne;
                imgOne.onload = function () {
                    setLoadingOne(false);
                };
                imgOne.onerror = function () {
                    setError(true);
                    setLoadingOne(false);
                    setLoadingTwo(false);
                };
            }

            if (itemTwo) {
                let imgTwo = document.createElement('img');
                imgTwo.src = itemTwo;

                imgTwo.onload = function () {
                    setLoadingTwo(false);
                };
                imgTwo.onerror = function () {
                    setError(true);
                    setLoadingOne(false);
                    setLoadingTwo(false);
                };
            }
        }
    }, [show, itemOne, itemTwo]);

    useEffect(() => {
        if (!show) {
            resetState();
        }
    }, [show]);

    useEffect(() => {
        // when we move slider disable panning for that period of time
        if (!isLoading()) {
            const handleRoot = document.querySelector('.__rcs-handle-root');

            const handleMouseDown = () => {
                setDisablePadding(true);
            };
            const handleMouseUp = () => {
                setDisablePadding(false);
            };

            if (handleRoot) {
                handleRoot.addEventListener('mousedown', handleMouseDown);
                handleRoot.addEventListener('mouseup', handleMouseUp);
            }

            return () => {
                if (handleRoot) {
                    handleRoot.removeEventListener('mousedown', handleMouseDown);
                    handleRoot.removeEventListener('mouseup', handleMouseUp);
                }
            };
        }
    }, [isLoading]);

    if (!show) {
        return null;
    }

    if (notFound) {
        return (
            <div className='slider__container'>
                <div className='slider__wrapper'>
                    <TextField style={{ color: 'white', fontSize: 20 }} value={notFoundText} />
                </div>
                {!hideCloseButton && (
                    <Button
                        type='default'
                        className='close-button'
                        shape='circle'
                        size='large'
                        icon={<CloseOutlined />}
                        onClick={closeHandler}
                    />
                )}
            </div>
        );
    }

    return (
        <div className='slider__container'>
            {!isLoading() && <Information projectName={projectName} fileName={fileName} />}
            <div className='slider__wrapper'>
                {isLoading() ? <Spin size='large' /> : renderMainContent()}
            </div>
            {!hideCloseButton && (
                <Button
                    type='default'
                    className='close-button'
                    shape='circle'
                    size='large'
                    icon={<CloseOutlined />}
                    onClick={closeHandler}
                />
            )}
        </div>
    );
};

export default CompareSliderModal;
