import { FC } from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Show } from '@refinedev/antd';
import { Typography } from 'antd';

const { Title } = Typography;

export const AuditLogsShow: FC<IResourceComponentsProps> = () => {
    return (
        <Show>
            <Title level={5}>Detailed audits logs</Title>
        </Show>
    );
};
