import { Button, Card, DatePicker, Form, FormProps, Input, Select } from 'antd';
import { useTranslate } from '@refinedev/core';
import { FC } from 'react';

const Filters: FC<{ formProps: FormProps }> = (props) => {
    const t = useTranslate();

    const { formProps } = props;

    const { RangePicker } = DatePicker;

    return (
        <Card title={t('auditsLogs.filters.title')}>
            <Form layout='vertical' {...formProps}>
                <Form.Item label={t('auditsLogs.fields.actor')} name='actor'>
                    <Input placeholder={t('auditsLogs.filters.actor.placeholder')} allowClear />
                </Form.Item>
                <Form.Item label={t('auditsLogs.fields.date')} name='date'>
                    <RangePicker style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label={t('auditsLogs.fields.entity')} name='entity'>
                    <Select
                        options={[
                            {
                                label: 'User',
                                value: 'user',
                            },
                            {
                                label: 'Factory',
                                value: 'factory',
                            },
                            {
                                label: 'Project',
                                value: 'project',
                            },
                            {
                                label: 'Archive',
                                value: 'archive',
                            },
                            {
                                label: 'File',
                                value: 'file',
                            },
                        ]}
                        allowClear
                        placeholder={t('auditsLogs.filters.entity.placeholder')}
                    />
                </Form.Item>
                <Form.Item label={t('auditsLogs.fields.particularEntity')} name='particularEntity'>
                    <Input
                        placeholder={t('auditsLogs.filters.particularEntity.placeholder')}
                        allowClear
                    />
                </Form.Item>
                <Form.Item label={t('auditsLogs.fields.action')} name='action'>
                    <Select
                        options={[
                            {
                                label: 'Created',
                                value: 'created',
                            },
                            {
                                label: 'Editing',
                                value: 'editing',
                            },
                            {
                                label: 'Deleting',
                                value: 'deleting',
                            },
                            {
                                label: 'Uploading',
                                value: 'uploading',
                            },
                            {
                                label: 'Uploaded',
                                value: 'uploaded',
                            },
                            {
                                label: 'Downloading',
                                value: 'downloading',
                            },
                            {
                                label: 'Watched',
                                value: 'watched',
                            },
                            {
                                label: 'Processing',
                                value: 'processing',
                            },
                            {
                                label: 'Stopping',
                                value: 'stopping',
                            },
                            {
                                label: 'Reset-password',
                                value: 'reset-password',
                            },
                        ]}
                        allowClear
                        placeholder={t('auditsLogs.filters.action.placeholder')}
                    />
                </Form.Item>
                <Form.Item>
                    <Button onClick={formProps.form?.submit} type='primary' size='large' block>
                        {t('auditsLogs.filters.submit')}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default Filters;
