import { FC } from 'react';
import { IResourceComponentsProps, useTranslate } from '@refinedev/core';
import { List, TextField, DateField, useTable, getDefaultSortOrder } from '@refinedev/antd';
import { Table, Row, Col } from 'antd';
import Filters from './Filters';
import { searchFunc } from './filterLogic';
import { DATE_FORMAT } from '../../constants';
import { Roles } from 'interfaces/roles';

interface AuditLog {
    id: string;
    entity: string;
    entityId: string;
    particularEntity: string;
    action: string;
    userId: string;
    createdAt: string;
    user: {
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        createdAt: string;
        updatedAt: string;
        deletedAt: null;
        isActive: boolean;
        role: Roles;
        description: string;
    };
}

export const AuditLogsList: FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { tableProps, sorters, searchFormProps } = useTable<AuditLog>({
        resource: 'system-audit',
        initialSorter: [
            {
                field: 'createdAt',
                order: 'desc',
            },
        ],
        onSearch: searchFunc,
    });

    return (
        <Row gutter={[16, 16]}>
            <Col xl={5} lg={24} xs={24}>
                <Filters formProps={searchFormProps} />
            </Col>
            <Col xl={19} xs={24}>
                <List>
                    <Table {...tableProps} rowKey='id'>
                        <Table.Column
                            dataIndex='createdAt'
                            key='createdAt'
                            title={t('auditsLogs.fields.date')}
                            render={(value) =>
                                value ? (
                                    <DateField value={value ? value : ''} format={DATE_FORMAT} />
                                ) : (
                                    '-'
                                )
                            }
                            defaultSortOrder={getDefaultSortOrder('title', sorters)}
                            sorter
                        />
                        <Table.Column
                            dataIndex='action'
                            key='action'
                            title={t('auditsLogs.fields.action')}
                            render={(value) => <TextField value={value} />}
                            defaultSortOrder={getDefaultSortOrder('title', sorters)}
                            sorter
                        />
                        <Table.Column
                            dataIndex='entity'
                            key='entity'
                            title={t('auditsLogs.fields.entity')}
                            render={(value) => <TextField value={value} />}
                            defaultSortOrder={getDefaultSortOrder('title', sorters)}
                            sorter
                        />
                        <Table.Column
                            dataIndex='particularEntity'
                            key='particularEntity'
                            title={t('auditsLogs.fields.particularEntity')}
                            render={(value) => <TextField value={value} />}
                            defaultSortOrder={getDefaultSortOrder('title', sorters)}
                            sorter
                        />

                        <Table.Column
                            dataIndex='user'
                            key='user'
                            title={t('auditsLogs.fields.actor')}
                            render={(value) => (
                                <TextField value={`${value.firstName} ${value.lastName}`} />
                            )}
                            defaultSortOrder={getDefaultSortOrder('title', sorters)}
                            sorter
                        />
                    </Table>
                </List>
            </Col>
        </Row>
    );
};
