import { MutableRefObject } from 'react';
import { ProcessingStatus } from './interfaces';
import {
    grey,
    lime,
    red,
    yellow,
    magenta,
    volcano,
    green,
    gold,
    orange,
    cyan,
    blue,
} from '@ant-design/colors';

export function debounce(this: any, func: Function, timeout = 300) {
    let timer: any;
    return (...args: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

export const getProjectBorderColor = (processingStatus?: ProcessingStatus): string => {
    switch (processingStatus) {
        case ProcessingStatus.PROCESSING:
            return green[1];
        case ProcessingStatus.STARTING:
            return lime[1];
        case ProcessingStatus.PENDING_PROCESSING:
            return gold[1];
        case ProcessingStatus.UPLOADING:
            return orange[1];
        case ProcessingStatus.CREATED:
            return grey[1];
        case ProcessingStatus.DONE:
            return magenta[1];
        case ProcessingStatus.PARTIALLY_PROCESSED:
            return volcano[1];
        case ProcessingStatus.PROCESSING_ERROR:
            return red[1];
        case ProcessingStatus.UPLOADING_ERROR:
            return red[1];
        case ProcessingStatus.ERROR:
            return red[1];
        case ProcessingStatus.STOPPING:
            return yellow[1];
        case ProcessingStatus.READY_FOR_PROCESSING:
            return cyan[1];
        default:
            return blue[5];
    }
};

export const getProjectStatusColor = (processingStatus?: ProcessingStatus): string => {
    switch (processingStatus) {
        case ProcessingStatus.PROCESSING:
            return green[0];
        case ProcessingStatus.STARTING:
            return lime[0];
        case ProcessingStatus.PENDING_PROCESSING:
            return gold[0];
        case ProcessingStatus.UPLOADING:
            return orange[0];
        case ProcessingStatus.CREATED:
            return grey[0];
        case ProcessingStatus.DONE:
            return magenta[0];
        case ProcessingStatus.PARTIALLY_PROCESSED:
            return volcano[0];
        case ProcessingStatus.PROCESSING_ERROR:
            return red[0];
        case ProcessingStatus.UPLOADING_ERROR:
            return red[0];
        case ProcessingStatus.ERROR:
            return red[0];
        case ProcessingStatus.STOPPING:
            return yellow[0];
        case ProcessingStatus.READY_FOR_PROCESSING:
            return cyan[0];
        default:
            return blue[0];
    }
};

export const getProjectStatusTagColor = (processingStatus?: ProcessingStatus): string => {
    switch (processingStatus) {
        case ProcessingStatus.PROCESSING:
            return 'green';
        case ProcessingStatus.STARTING:
            return 'lime';
        case ProcessingStatus.PENDING_PROCESSING:
            return 'gold';
        case ProcessingStatus.UPLOADING:
            return 'orange';
        case ProcessingStatus.CREATED:
            return '';
        case ProcessingStatus.DONE:
            return 'magenta';
        case ProcessingStatus.PARTIALLY_PROCESSED:
            return 'volcano';
        case ProcessingStatus.PROCESSING_ERROR:
            return 'red';
        case ProcessingStatus.UPLOADING_ERROR:
            return 'red';
        case ProcessingStatus.ERROR:
            return 'red';
        case ProcessingStatus.STOPPING:
            return 'yellow';
        case ProcessingStatus.READY_FOR_PROCESSING:
            return 'cyan';
        default:
            return 'blue';
    }
};

export function getScaledImageCoordinates(
    canvasWidth: number,
    canvasHeight: number,
    imageWidth: number,
    imageHeight: number
) {
    const widthRatio = canvasWidth / imageWidth;
    const heightRatio = canvasHeight / imageHeight;
    const bestRatio = Math.min(widthRatio, heightRatio) * 0.95;
    const width = imageWidth * bestRatio;
    const height = imageHeight * bestRatio;

    return {
        width,
        height,
        x: (canvasWidth - width) / 2,
        y: (canvasHeight - height) / 2,
    };
}

export function convertPerspectivePointsRelativeToImage(
    imageRef: MutableRefObject<any>,
    shape: {
        id: string;
        x: number;
        y: number;
    }
) {
    return {
        id: shape.id,
        x: Number(
            ((shape.x - imageRef.current?.position().x) / imageRef.current?.width()).toFixed(3)
        ),
        y: Number(
            ((shape.y - imageRef.current?.position().y) / imageRef.current?.height()).toFixed(3)
        ),
    };
}

export function convertRectanglePointsRelativeToImage(
    imageRef: MutableRefObject<any>,
    shape: {
        width: number;
        height: number;
        id: string;
        x: number;
        y: number;
    }
) {
    return {
        id: shape.id,
        width: Number((shape.width / imageRef.current?.width()).toFixed(3)),
        height: Number((shape.height / imageRef.current?.height()).toFixed(3)),
        x: Number(
            ((shape.x - imageRef.current?.position().x) / imageRef.current?.width()).toFixed(3)
        ),
        y: Number(
            ((shape.y - imageRef.current?.position().y) / imageRef.current?.height()).toFixed(3)
        ),
    };
}
