import React from 'react';
import * as ReactDOMClient from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import App from './App';
import './i18n';

const container = document.getElementById('root') as Element;

const root = ReactDOMClient.createRoot(container);

root.render(
    <React.StrictMode>
        <React.Suspense fallback='loading'>
            <App />
        </React.Suspense>
    </React.StrictMode>
);

reportWebVitals();
