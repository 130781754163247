import { FC } from 'react';
import { useTranslate, IResourceComponentsProps, useShow, usePermissions } from '@refinedev/core';
import { Show, MarkdownField } from '@refinedev/antd';
import { Typography } from 'antd';

import { IFactory } from 'interfaces';
import { Roles } from 'interfaces/roles';

const { Title } = Typography;

export const FactoriesShow: FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { queryResult } = useShow<IFactory>();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const { data: permissionsData } = usePermissions<string[]>();

    return (
        <Show canEdit={permissionsData?.includes(Roles.ADMIN)} isLoading={isLoading}>
            <Title level={3}>{t('factories.fields.name')}</Title>
            <Typography.Text>{record?.name}</Typography.Text>

            <Title level={5}>{t('factories.fields.id')}</Title>
            <Typography.Text>{record?.id}</Typography.Text>
            <Title level={5}>{t('factories.fields.description')}</Title>
            <MarkdownField value={record?.description} />
        </Show>
    );
};
