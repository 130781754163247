import { Button, Select } from 'antd';
import { FC } from 'react';

interface TopSectionProps {
    canvasWidth: number;
    onOptionChange: (value: string) => void;
    onSaveSettings: () => void;
}

const TopSection: FC<TopSectionProps> = ({
    canvasWidth,
    onOptionChange: changeOption,
    onSaveSettings: saveSettings,
}) => {
    return (
        <>
            <Select
                defaultValue='Deleting areas'
                style={{
                    width: 180,
                    position: 'relative',
                    left: canvasWidth - 180,
                    bottom: 10,
                }}
                onChange={changeOption}
                options={[
                    { value: 'Deleting areas', label: 'Deleting areas' },
                    { value: 'Perpesctive', label: 'Perspective' },
                    { value: 'Brightness/Contrast', label: 'Brightness/Contrast' },
                ]}
            />
            <Button
                type='default'
                style={{ position: 'relative', left: canvasWidth - 480, bottom: 10 }}
                onClick={saveSettings}
            >
                Save settings
            </Button>
        </>
    );
};

export default TopSection;
