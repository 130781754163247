import { useEffect, useState, FC } from 'react';
import { Authenticated, useCustom, useNavigation } from '@refinedev/core';
import CompareSliderModal from 'components/CompareSlider';
import { IFile, ParamsTypes } from 'interfaces';
import routerProvider from '@refinedev/react-router-v6/legacy';
import { API_ROOT } from '../../constants';

export const ViewerShow: FC = () => {
    const { projectID, fileID } = routerProvider.useParams<ParamsTypes>();
    const { show, list } = useNavigation();

    const [isFileNotFound, setIsFileNotFound] = useState<boolean>(false);
    const [file, setFile] = useState<any>(null);

    const { data: dataDefects } = useCustom<any>({
        url: `${API_ROOT}/defects`,
        method: 'get',
    });

    const {
        data: projectData,
        isLoading: isProjectDataLoading,
        isError: isProjectDataResponseError,
    } = useCustom<any>({
        url: `${API_ROOT}/projects/${projectID}`,
        method: 'get',
    });

    const defectsMapping = dataDefects?.data;

    const {
        data: projectFiles,
        isLoading: isProjectFilesLoading,
        isError: isProjectFilesResponseError,
    } = useCustom<any>({
        url: `${API_ROOT}/files`,
        method: 'get',
        config: {
            query: {
                projectId: projectID,
            },
        },
    });

    useEffect(() => {
        if (!isProjectFilesLoading && !isProjectDataLoading) {
            const filesList = projectFiles?.data.data.map((files: IFile) => ({
                ...files,
            }));

            if (filesList) {
                const file = filesList.find((el: { id: string }) => el.id === fileID);
                if (file) {
                    setFile(file);
                } else {
                    // incorrect  file ID
                    setIsFileNotFound(true);
                }
            } else {
                // incorrect project ID
                setIsFileNotFound(true);
            }
        }
    }, [fileID, isProjectFilesLoading, isProjectDataLoading, projectFiles?.data.data]);

    const handleClose = () => {
        if (isFileNotFound) {
            list('projects');
            return;
        }
        show('projects', projectID);
    };

    const getNotFoundText = () => {
        if (isProjectDataResponseError || isProjectFilesResponseError) {
            return 'Project with requested id not found.';
        }
        return 'File with requested id not found in the project.';
    };

    return (
        <Authenticated>
            {
                <CompareSliderModal
                    show
                    notFound={isFileNotFound}
                    notFoundText={getNotFoundText()}
                    fileName={file?.fileName}
                    projectName={projectData?.data.name || 'Untitled'}
                    itemOne={file?.originalFileUrl}
                    itemTwo={file?.processedFileUrl}
                    defects={file?.processingResult?.defects || []}
                    defectsMapping={defectsMapping}
                    onClose={handleClose}
                />
            }
        </Authenticated>
    );
};
