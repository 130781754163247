import { FC } from 'react';
import './index.less';

interface InformationProps {
    projectName: string;
    fileName: string;
}

const Information: FC<InformationProps> = ({ projectName, fileName }) => {
    return <div className='information'>{`${projectName} - ${fileName}`}</div>;
};

export default Information;
