export const searchFunc = (values: any) => {
    const { actor, date, entity, particularEntity, action } = values;
    const actorFirstName = actor?.split(' ')[0];
    const actorLastName = actor?.split(' ')[1];

    return [
        {
            field: 'user.firstName',
            operator: 'contains' as const,
            value: actorFirstName ? actorFirstName : undefined,
        },
        {
            field: 'user.lastName',
            operator: 'contains' as const,
            value: actorLastName ? actorLastName : undefined,
        },
        {
            field: 'createdAt',
            operator: 'gte' as const,
            value: date ? date[0].startOf('day').toISOString() : undefined,
        },
        {
            field: 'createdAt',
            operator: 'lte' as const,
            value: date ? date[1].endOf('day').toISOString() : undefined,
        },
        {
            field: 'entity',
            operator: 'eq' as const,
            value: entity ? entity : undefined,
        },
        {
            field: 'particularEntity',
            operator: 'contains' as const,
            value: particularEntity ? particularEntity : undefined,
        },
        {
            field: 'action',
            operator: 'eq' as const,
            value: action ? action : undefined,
        },
    ];
};
