import { useState, FC } from 'react';
import {
    useTranslate,
    IResourceComponentsProps,
    usePermissions,
    useNotification,
} from '@refinedev/core';
import { Edit, useForm, useSelect } from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons';

import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';

import { axiosInstance } from '../../App';

import 'react-mde/lib/styles/css/react-mde-all.css';

import { IFactory, IUser } from 'interfaces';
import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';
import * as constants from '../../constants';

export const UsersEdit: FC<IResourceComponentsProps> = () => {
    const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write');

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const t = useTranslate();
    const notification = useNotification();

    const { formProps, saveButtonProps } = useForm<IUser>();
    const { data } = usePermissions<string[]>();

    const { selectProps: factoriesSelectProps } = useSelect<IFactory>({
        resource: 'factories',
        optionLabel: 'name',
        optionValue: 'id',

        pagination: {
            mode: 'server',
        },
    });

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = async () => {
        setConfirmLoading(true);
        axiosInstance
            .post(`${constants.API_ROOT}/auth/forgot-password`, {
                email: formProps.initialValues?.email,
            })
            .then((response) => {
                if (response.data) {
                    notification.open?.({
                        message: t('notifications.success'),
                        type: 'success',
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                notification.open?.({
                    message: t('notifications.requestError'),
                    type: 'error',
                });
            })
            .finally(() => {
                setOpen(false);
                setConfirmLoading(false);
            });
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <Edit
            saveButtonProps={saveButtonProps}
            canDelete={data?.includes('admin')}
            headerButtons={({ defaultButtons }) => (
                <>
                    <Button type='primary' onClick={showModal} icon={<Icons.KeyOutlined />}>
                        {t('users.resetPasswordModal.passwordResetButton')}
                    </Button>
                    {defaultButtons}
                </>
            )}
        >
            <Form
                {...formProps}
                initialValues={{
                    id: formProps.initialValues?.id,
                    ...formProps.initialValues,
                }}
                layout='vertical'
            >
                <Form.Item
                    style={{ display: 'none' }}
                    label={t('users.fields.id')}
                    name='id'
                    rules={[
                        {
                            required: true,
                        },
                        {
                            min: 5,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('users.fields.email')}
                    name='email'
                    rules={[
                        {
                            required: true,
                            type: 'email',
                        },
                        {
                            min: 5,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('users.fields.firstName')}
                    name='firstName'
                    rules={[
                        {
                            required: true,
                        },
                        {
                            min: 1,
                            max: 50,
                        },
                        {
                            pattern: new RegExp('^[a-zA-Z-]*$'),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('users.fields.lastName')}
                    name='lastName'
                    rules={[
                        {
                            required: true,
                        },
                        {
                            min: 1,
                            max: 50,
                        },
                        {
                            pattern: new RegExp('^[a-zA-Z-]*$'),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('users.fields.factory')}
                    name='factories'
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select mode='multiple' {...factoriesSelectProps} />
                </Form.Item>
                <Form.Item
                    label={t('users.fields.role')}
                    name='role'
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        options={[
                            {
                                label: t('users.fields.roles.admin'),
                                value: 'admin',
                            },
                            {
                                label: t('users.fields.roles.operator'),
                                value: 'operator',
                            },
                            {
                                label: t('users.fields.roles.partner'),
                                value: 'partner',
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    label={t('users.fields.isActive')}
                    name='isActive'
                    valuePropName='checked'
                >
                    <Checkbox>Active</Checkbox>
                </Form.Item>
                <Form.Item label={t('projects.fields.description')} name='description'>
                    <ReactMde
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={(markdown) =>
                            Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                        }
                    />
                </Form.Item>
            </Form>
            <Modal
                title={t('users.resetPasswordModal.title')}
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <p>{t('users.resetPasswordModal.text')}</p>
            </Modal>
        </Edit>
    );
};
