import { FC, useEffect, useRef, useState } from 'react';
import Konva from 'konva';
import useImage from 'use-image';
import { v4 as uuidv4 } from 'uuid';
import { CloseOutlined } from '@ant-design/icons';
import { Spin, Button, theme } from 'antd';
import { Stage, Layer, Image, Star, Rect } from 'react-konva';
import Selector from './Selector';
import {
    convertPerspectivePointsRelativeToImage,
    convertRectanglePointsRelativeToImage,
    getScaledImageCoordinates,
} from 'utils';
import styles from './style.module.less';
import TopSection from './SelectOptions';
import ActionButtons from './ActionButtons';
import ChangeBrightnessContrast from './ChangeBrightnessContrast';

const { useToken } = theme;

interface PreprocessingProps {
    image: string;
    onClose: () => void;
}

export interface Rectangle {
    x: number;
    y: number;
    width: number;
    height: number;
    id: string;
}

const Preprocesing: FC<PreprocessingProps> = ({ image, onClose: close }) => {
    const { token } = useToken();
    const [canvasWidth, setCanvasWidth] = useState<number>(window.innerWidth * 0.8);
    const [canvasHeight, setCanvasHeight] = useState<number>(window.innerHeight * 0.8);
    const [scale, setScale] = useState({ x: 1, y: 1 });
    const [option, setOption] = useState<string>('Deleting areas');
    const [cursor, setCursor] = useState<string>('default');
    // Deleting areas logic state
    const [sectionList, setSectionList] = useState<Array<Rectangle>>([]);
    const [isDrawActive, setIsDrawActive] = useState<boolean>(false);
    const [isDeletingActive, setIsDeletingActive] = useState<boolean>(false);
    // Brightness/Contrast logic state
    const [contrast, setContrast] = useState<number>(0);
    const [brightness, setBrightness] = useState<number>(0);
    //Perspective transformation logic state
    const [perpesctivePoints, setPerspectivePoints] = useState([
        { id: uuidv4(), x: 0.25, y: 0.25 },
        { id: uuidv4(), x: 0.75, y: 0.25 },
        { id: uuidv4(), x: 0.25, y: 0.75 },
        { id: uuidv4(), x: 0.75, y: 0.75 },
    ]);

    const imageRef = useRef<any>(null);
    const stageRef = useRef<any>(null);

    const [imgElement, imgStatus] = useImage(image, 'anonymous');

    const handleChangeBrightness = (value: number) => setBrightness(value / 100);
    const handleChangeContrast = (value: number) => setContrast(value);
    const handleAddSection = (data: Rectangle) =>
        setSectionList((state) => [
            convertRectanglePointsRelativeToImage(imageRef, data),
            ...state,
        ]);
    const handlePerspectivePointDragMove = (e: Konva.KonvaEventObject<DragEvent>) =>
        setPerspectivePoints(
            perpesctivePoints.map((point) =>
                point.id === e.target.attrs.id
                    ? convertPerspectivePointsRelativeToImage(imageRef, {
                          ...point,
                          x: e.target.attrs.x,
                          y: e.target.attrs.y,
                      })
                    : point
            )
        );

    const handleOptionChange = (value: string) => {
        setOption(value);
        handleResetZoom();
        setCursor('default');
    };
    const handleZoomIn = () => {
        setIsDrawActive(false);
        setIsDeletingActive(false);
        setCursor('default');
        setScale(({ x, y }: { x: number; y: number }) => {
            const newXScale = x + 0.25;
            const newYScale = y + 0.25;

            const oldCenter = {
                x: stageRef.current.width() / 2,
                y: stageRef.current.height() / 2,
            };

            const newCenter = {
                x: (stageRef.current.width() * newXScale) / 2,
                y: (stageRef.current.height() * newYScale) / 2,
            };

            stageRef.current.position({
                x: oldCenter.x - newCenter.x,
                y: oldCenter.y - newCenter.y,
            });

            return {
                x: newXScale,
                y: newYScale,
            };
        });
    };

    const handleZoomOut = () => {
        setIsDrawActive(false);
        setIsDeletingActive(false);
        setCursor('default');
        setScale(({ x, y }: { x: number; y: number }) => {
            if (x > 1) {
                const newXScale = x - 0.25;
                const newYScale = y - 0.25;

                const oldCenter = {
                    x: stageRef.current.width() / 2,
                    y: stageRef.current.height() / 2,
                };

                const newCenter = {
                    x: (stageRef.current.width() * newXScale) / 2,
                    y: (stageRef.current.height() * newYScale) / 2,
                };

                stageRef.current.position({
                    x: oldCenter.x - newCenter.x,
                    y: oldCenter.y - newCenter.y,
                });
                return {
                    x: newXScale,
                    y: newYScale,
                };
            }
            return { x: 1, y: 1 };
        });
    };
    const handleResetZoom = () => {
        setIsDrawActive(false);
        setIsDeletingActive(false);
        setCursor('default');
        setScale(({ x }: { x: number }) => {
            if (x > 1) {
                const newXScale = 1;
                const newYScale = 1;

                const oldCenter = {
                    x: stageRef.current.width() / 2,
                    y: stageRef.current.height() / 2,
                };

                const newCenter = {
                    x: (stageRef.current.width() * newXScale) / 2,
                    y: (stageRef.current.height() * newYScale) / 2,
                };

                stageRef.current.position({
                    x: oldCenter.x - newCenter.x,
                    y: oldCenter.y - newCenter.y,
                });
                return {
                    x: newXScale,
                    y: newYScale,
                };
            }
            return { x: 1, y: 1 };
        });
    };

    const handleActivateDrawTool = () => {
        setIsDrawActive(true);
        setIsDeletingActive(false);
        setCursor('crosshair');
    };

    const handleActivateDeletingTool = () => {
        setIsDrawActive(false);
        setIsDeletingActive(true);
        setCursor(`url('/remove.png'), auto`);
    };
    const handleSaveSettings = () => {
        console.log('Deleting areas:', sectionList);
        console.log('Perspective:', perpesctivePoints);
        console.log('Brightness/Contrast:', { brightness, contrast });
    };

    const handleDeleteRectangle = (id: string) => {
        if (isDeletingActive) {
            setSectionList(sectionList.filter((section) => section.id !== id));
            setIsDeletingActive(false);
            setCursor('default');
        }
    };

    const handleEnterRectangle = (e: any) => {
        isDeletingActive && e.target.strokeWidth(4);
    };
    const handleLeaveRectangle = (e: any) => {
        e.target.strokeWidth(2);
    };

    const handleDisableDraw = () => {
        setIsDrawActive(false);
        setCursor('default');
    };

    const dragBoundFunc = (pos: { x: number; y: number }) => {
        if (imageRef.current) {
            const leftImageBorder = imageRef.current.getAbsolutePosition().x;

            const topImageBorder = imageRef.current.getAbsolutePosition().y;
            const rightImageBorder =
                imageRef.current.getAbsolutePosition().x +
                imageRef.current.width() * stageRef.current.scale().x;
            const bottomImageBorder =
                imageRef.current.getAbsolutePosition().y +
                imageRef.current.height() * stageRef.current.scale().x;

            if (pos.x < leftImageBorder && pos.y < topImageBorder) {
                return { x: leftImageBorder, y: topImageBorder };
            }
            if (pos.x > rightImageBorder && pos.y < topImageBorder) {
                return {
                    x: rightImageBorder,
                    y: topImageBorder,
                };
            }
            if (pos.x > rightImageBorder && pos.y > bottomImageBorder) {
                return { x: rightImageBorder, y: bottomImageBorder };
            }
            if (pos.x < leftImageBorder && pos.y > bottomImageBorder) {
                return { x: leftImageBorder, y: bottomImageBorder };
            }
            if (pos.x < leftImageBorder) {
                return { x: leftImageBorder, y: pos.y };
            }
            if (pos.y < topImageBorder) {
                return { x: pos.x, y: topImageBorder };
            }
            if (pos.x > rightImageBorder) {
                return { x: rightImageBorder, y: pos.y };
            }
            if (pos.y > bottomImageBorder) {
                return { x: pos.x, y: bottomImageBorder };
            }
        }

        return pos;
    };

    useEffect(() => {
        // for konva filters work properly  - https://konvajs.org/docs/filters/Brighten.html
        if (imgElement) {
            if (imageRef.current) {
                imageRef.current.cache();
            }
        }
    });

    useEffect(() => {
        function resizeCanvas() {
            setCanvasWidth(window.innerWidth / 2);
            setCanvasHeight(window.innerHeight / 2);
        }

        window.addEventListener('resize', resizeCanvas);

        return () => {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, []);

    useEffect(() => {
        // exit from component by Esc
        const keyDownHandler = (event: { key: string; preventDefault: () => void }) => {
            if (event.key === 'Escape') {
                event.preventDefault();

                if (close) close();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [close]);

    return (
        <div className={styles['container']}>
            <Button
                className={styles['close-button']}
                shape='circle'
                size='large'
                icon={<CloseOutlined />}
                onClick={close}
            />
            {imgStatus === 'loading' && <Spin size='large' />}
            {imgStatus === 'loaded' && (
                <div id='main-section'>
                    <TopSection
                        canvasWidth={canvasWidth}
                        onOptionChange={handleOptionChange}
                        onSaveSettings={handleSaveSettings}
                    />
                    <div id='stage-with-action-buttons' style={{ display: 'flex', gap: 15 }}>
                        <Stage
                            className={styles['stage']}
                            style={{
                                cursor,
                            }}
                            draggable={scale.x > 1 && !isDrawActive && !isDeletingActive}
                            scale={scale}
                            ref={stageRef}
                            width={canvasWidth}
                            height={canvasHeight}
                        >
                            <Layer>
                                <Image
                                    ref={imageRef}
                                    {...getScaledImageCoordinates(
                                        canvasWidth,
                                        canvasHeight,
                                        imgElement?.naturalWidth ?? 0,
                                        imgElement?.naturalHeight ?? 0
                                    )}
                                    image={imgElement}
                                    filters={[Konva.Filters.Brighten, Konva.Filters.Contrast]}
                                    brightness={brightness}
                                    contrast={contrast}
                                />
                                {option === 'Deleting areas' && (
                                    <>
                                        <Selector
                                            stageRef={stageRef}
                                            imageRef={imageRef}
                                            isDrawActive={isDrawActive}
                                            onAddSection={handleAddSection}
                                            onDisableDraw={handleDisableDraw}
                                        />
                                        {sectionList.map(({ id, x, y, width, height }) => (
                                            <Rect
                                                key={id}
                                                x={
                                                    x * imageRef.current.width() +
                                                    imageRef.current.position().x
                                                }
                                                y={
                                                    y * imageRef.current.height() +
                                                    imageRef.current.position().y
                                                }
                                                width={width * imageRef.current.width()}
                                                height={height * imageRef.current.height()}
                                                dragBoundFunc={dragBoundFunc}
                                                stroke={token.colorPrimary}
                                                fill={'rgba(25, 118, 210, 0.25)'}
                                                hitStrokeWidth={2.5}
                                                onMouseEnter={handleEnterRectangle}
                                                onMouseLeave={handleLeaveRectangle}
                                                onClick={() => handleDeleteRectangle(id)}
                                            />
                                        ))}
                                    </>
                                )}
                                {option === 'Perpesctive' &&
                                    perpesctivePoints.map(({ id, x, y }) => (
                                        <Star
                                            x={
                                                x * imageRef.current.width() +
                                                imageRef.current.position().x
                                            }
                                            y={
                                                y * imageRef.current.height() +
                                                imageRef.current.position().y
                                            }
                                            key={id}
                                            id={id}
                                            numPoints={5}
                                            innerRadius={5}
                                            outerRadius={10}
                                            fill={token.colorPrimary}
                                            stroke='black'
                                            strokeWidth={0.5}
                                            draggable
                                            onMouseEnter={() => setCursor('pointer')}
                                            onMouseLeave={() => setCursor('default')}
                                            dragBoundFunc={dragBoundFunc}
                                            onDragMove={handlePerspectivePointDragMove}
                                        />
                                    ))}
                            </Layer>
                        </Stage>
                        <ActionButtons
                            option={option}
                            isDeletingActive={isDeletingActive}
                            isDrawActive={isDeletingActive}
                            onHandleActivateDeletingTool={handleActivateDeletingTool}
                            onHandleActivateDrawTool={handleActivateDrawTool}
                            onHandleZoomIn={handleZoomIn}
                            onHandleZoomOut={handleZoomOut}
                            onHandleResetZoom={handleResetZoom}
                        />
                    </div>
                    <ChangeBrightnessContrast
                        option={option}
                        onChangeBrightness={handleChangeBrightness}
                        onChangeContrast={handleChangeContrast}
                    />
                </div>
            )}
            {imgStatus === 'failed' && <div>Image loading error</div>}
        </div>
    );
};

export default Preprocesing;
