import { useState, FC } from 'react';
import { useTranslate, IResourceComponentsProps } from '@refinedev/core';
import { Create, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';

import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';

import 'react-mde/lib/styles/css/react-mde-all.css';

import { IFactory, IProject } from 'interfaces';

export const ProjectsCreate: FC<IResourceComponentsProps> = () => {
    const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write');

    const t = useTranslate();

    const { formProps, saveButtonProps } = useForm<IProject>();

    const { selectProps: factoriesSelectProps } = useSelect<IFactory>({
        resource: 'factories',
        optionLabel: 'name',
        optionValue: 'id',
        filters: [
            {
                field: 'deleted',
                operator: 'eq',
                value: false,
            },
        ],
        pagination: {
            mode: 'server',
        },
    });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                initialValues={{
                    ...formProps.initialValues,
                }}
                layout='vertical'
            >
                <Form.Item
                    label={t('projects.fields.name')}
                    name='name'
                    rules={[
                        {
                            required: true,
                        },
                        {
                            min: 1,
                            max: 50,
                        },
                        {
                            pattern: new RegExp('^[a-zA-Z0-9-_]*$'),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('projects.fields.factory')}
                    name='factoryId'
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select {...factoriesSelectProps} />
                </Form.Item>
                <Form.Item label={t('projects.fields.description')} name='description'>
                    <ReactMde
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={(markdown) =>
                            Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                        }
                    />
                </Form.Item>
            </Form>
        </Create>
    );
};
