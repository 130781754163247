import React, { FC } from 'react';
import { useTranslate, IResourceComponentsProps, useShow, useOne } from '@refinedev/core';
import { Show, BooleanField, UrlField, DateField } from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons';

import { Typography, Table, Space, Button } from 'antd';

import { IArchive, IArchiveFile, IProject } from 'interfaces';
import { DATE_FORMAT } from '../../constants';
import { Col, Row, Spin } from 'antd';

const { Title } = Typography;

export const ArchivesShow: FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { queryResult } = useShow<IArchive>();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const { data: project, isLoading: projectLoading } = useOne<IProject>({
        resource: 'projects',
        id: record?.projectId || '',
    });

    return (
        <Show isLoading={isLoading}>
            <Row gutter={[16, 16]}>
                <Col xl={5} lg={24} xs={24}>
                    <Title level={3}>{t('archives.fields.id')}</Title>
                    <Typography.Text>{record?.id}</Typography.Text>
                    <Title level={5}>{t('archives.fields.isCompleted')}</Title>
                    <Typography.Text>
                        <BooleanField value={record?.isCompleted} />
                    </Typography.Text>
                    <Title level={5}>{t('archives.fields.projectName')}</Title>
                    <Typography.Text>
                        {projectLoading ? (
                            <Spin />
                        ) : (
                            <UrlField value={`/projects/show/${record?.projectId}`}>
                                {project?.data.name}
                            </UrlField>
                        )}
                    </Typography.Text>
                    <Title level={5}>{t('archives.fields.createdAt')}</Title>
                    <Typography.Text>
                        <DateField format={DATE_FORMAT} value={record?.createdAt} />
                    </Typography.Text>
                    <Title level={5}>{t('archives.fields.completedAt')}</Title>
                    <Typography.Text>
                        <DateField format={DATE_FORMAT} value={record?.completedAt} />
                    </Typography.Text>
                </Col>
                <Col xl={19} xs={24}>
                    <Title level={5}>{t('archives.fields.files')}</Title>
                    <Table size='small' bordered dataSource={record?.files}>
                        <Table.Column
                            dataIndex='fileName'
                            key='fileName'
                            title={t('files.fields.fileName')}
                        />
                        <Table.Column
                            dataIndex='createdAt'
                            key='createdAt'
                            title={t('files.fields.createdAt')}
                            render={(value) =>
                                value ? (
                                    <DateField value={value ? value : ''} format={DATE_FORMAT} />
                                ) : (
                                    '-'
                                )
                            }
                        />
                        <Table.Column
                            dataIndex='isCompleted'
                            key='isCompleted'
                            title={t('files.fields.isCompleted')}
                            render={(value) => <BooleanField value={value} />}
                        />
                        <Table.Column<IArchiveFile>
                            title={t('table.actions')}
                            dataIndex='actions'
                            render={(_, record) => (
                                <Space>
                                    <Button
                                        onClick={() => window.open(record.url)}
                                        icon={<Icons.DownloadOutlined />}
                                        size='small'
                                    />
                                </Space>
                            )}
                        />
                    </Table>
                </Col>
            </Row>
        </Show>
    );
};
