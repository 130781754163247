import { FC } from 'react';
import { useTranslate, IResourceComponentsProps, useShow, usePermissions } from '@refinedev/core';
import { Show, MarkdownField, useSelect } from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons';

import { Typography, Tag } from 'antd';

import { Roles } from 'interfaces/roles';
import { IFactory, IUser } from 'interfaces';

const { Title } = Typography;

export const UsersShow: FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { queryResult } = useShow<IUser>();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const { data: permissionsData } = usePermissions<string[]>();

    const { selectProps: factoriesSelectProps } = useSelect<IFactory>({
        resource: 'factories',
        optionLabel: 'name',
        optionValue: 'id',

        pagination: {
            mode: 'server',
        },
    });

    return (
        <Show canEdit={permissionsData?.includes(Roles.ADMIN)} isLoading={isLoading}>
            <Title level={5}>{t('users.fields.email')}</Title>
            <Typography.Text>
                <Icons.MailOutlined /> {record?.email}
            </Typography.Text>

            <Title level={5}>{t('users.fields.name')}</Title>
            <Typography.Text>
                {record?.firstName} {record?.lastName}
            </Typography.Text>

            <Title level={5}>{t('users.fields.role')}</Title>
            <Typography.Text>
                <Tag>{record?.role}</Tag>
            </Typography.Text>

            <Title level={5}>{t('users.fields.factories')}</Title>
            {factoriesSelectProps?.options?.map((company) => (
                <Typography.Text key={company.value}>
                    <Tag>{company.label}</Tag>
                </Typography.Text>
            ))}

            <Title level={5}>{t('users.fields.description')}</Title>
            <Typography.Text>
                <MarkdownField value={record?.description} />
            </Typography.Text>
        </Show>
    );
};
