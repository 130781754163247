import { FC } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import ProjectsTimeline from '../../components/ProjectsTimeline';

const { Text } = Typography;

export const DashboardPage: FC = () => {
    const { t } = useTranslation();

    return (
        <Row gutter={[16, 16]}>
            <Col xs={12}>
                <Card title={<Text strong>{t('projects.timeline.title')}</Text>}>
                    <ProjectsTimeline />
                </Card>
            </Col>
        </Row>
    );
};
