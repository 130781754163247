import { FC } from 'react';
import { useTranslate } from '@refinedev/core';
import { Tag } from 'antd';
import { ProcessingStatus } from '../../interfaces';
import { getProjectStatusTagColor } from '../../utils';

type StatusProps = {
    status?: ProcessingStatus;
};

export const ProjectProcessingStatus: FC<StatusProps> = ({ status }) => {
    const t = useTranslate();

    return (
        <Tag color={getProjectStatusTagColor(status)}>{t(`enum.processingStatuses.${status}`)}</Tag>
    );
};
