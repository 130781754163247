import { FC } from 'react';
import { useTranslate, IResourceComponentsProps, usePermissions } from '@refinedev/core';

import {
    List,
    TextField,
    useTable,
    getDefaultSortOrder,
    DateField,
    EditButton,
    DeleteButton,
    ShowButton,
    BooleanField,
} from '@refinedev/antd';

import { Table, Space } from 'antd';
import { IFactory } from 'interfaces';
import { Roles } from 'interfaces/roles';
import { DATE_FORMAT } from '../../constants';

export const FactoriesList: FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { tableProps, sorters: sorter } = useTable<IFactory>({
        sorters: {
            initial: [
                {
                    field: 'id',
                    order: 'desc',
                },
            ],
        },
    });

    const { data: permissionsData } = usePermissions<string[]>();

    return (
        <List
            canCreate={
                permissionsData?.includes(Roles.ADMIN) || permissionsData?.includes(Roles.OPERATOR)
            }
        >
            <Table {...tableProps} rowKey='id'>
                <Table.Column
                    dataIndex='name'
                    key='name'
                    title={t('factories.fields.name')}
                    render={(value) => <TextField value={value} />}
                    defaultSortOrder={getDefaultSortOrder('title', sorter)}
                    sorter
                />
                <Table.Column
                    dataIndex='description'
                    key='description'
                    title={t('factories.fields.description')}
                    render={(value) => <TextField value={value} />}
                />
                {permissionsData?.includes(Roles.ADMIN) && (
                    <Table.Column
                        dataIndex='deleted'
                        key='deleted'
                        title={t('factories.fields.deleted')}
                        render={(value) => {
                            return <BooleanField value={value} />;
                        }}
                        defaultSortOrder={getDefaultSortOrder('deleted', sorter)}
                        sorter
                    />
                )}
                <Table.Column
                    dataIndex='createdAt'
                    key='createdAt'
                    title={t('factories.fields.createdAt')}
                    render={(value) =>
                        value ? <DateField value={value ? value : ''} format={DATE_FORMAT} /> : '-'
                    }
                    defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
                    sorter
                />
                <Table.Column
                    dataIndex='updatedAt'
                    key='updatedAt'
                    title={t('factories.fields.updatedAt')}
                    render={(value) =>
                        value ? <DateField value={value ? value : ''} format={DATE_FORMAT} /> : '-'
                    }
                    defaultSortOrder={getDefaultSortOrder('updatedAt', sorter)}
                    sorter
                />
                {/* <Table.Column
          dataIndex={["category", "id"]}
          title={t("posts.fields.category.title")}
          render={(value) => {
            if (isLoading) {
              return <TextField value={t("loading")} />;
            }

            return (
              <TextField
                value={
                  categoriesData?.data.find((item) => item.id === value)?.title
                }
              />
            );
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder={t("posts.fields.category.filter.placeholder")}
                {...categorySelectProps}
              />
            </FilterDropdown>
          )}
        /> */}
                <Table.Column<IFactory>
                    title={t('table.actions')}
                    dataIndex='actions'
                    render={(_, record) => (
                        <Space>
                            <ShowButton hideText size='small' recordItemId={record.id} />
                            {permissionsData?.includes(Roles.ADMIN) && (
                                <EditButton hideText size='small' recordItemId={record.id} />
                            )}
                            {permissionsData?.includes(Roles.ADMIN) && (
                                <DeleteButton hideText size='small' recordItemId={record.id} />
                            )}
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
