import {
    DeleteOutlined,
    HighlightOutlined,
    ReloadOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import { Button, theme } from 'antd';
import styles from '../style.module.less';
import { FC } from 'react';

const { useToken } = theme;

interface ActionButtonsProps {
    option: string;
    isDrawActive: boolean;
    isDeletingActive: boolean;
    onHandleActivateDrawTool: () => void;
    onHandleActivateDeletingTool: () => void;
    onHandleZoomIn: () => void;
    onHandleZoomOut: () => void;
    onHandleResetZoom: () => void;
}

const ActionButtons: FC<ActionButtonsProps> = ({
    option,
    isDrawActive,
    isDeletingActive,
    onHandleActivateDrawTool: activateDrawTool,
    onHandleActivateDeletingTool: activateDeletingTool,
    onHandleZoomIn: zoomIn,
    onHandleZoomOut: zoomOut,
    onHandleResetZoom: resetZoom,
}) => {
    const { token } = useToken();

    return (
        <div className={styles['button-wrapper']}>
            {option === 'Deleting areas' && (
                <>
                    <Button size='large' shape='circle' onClick={activateDrawTool} type='default'>
                        <HighlightOutlined
                            style={{
                                color: isDrawActive ? token.colorPrimary : '',
                            }}
                        />
                    </Button>
                    <Button
                        size='large'
                        shape='circle'
                        onClick={activateDeletingTool}
                        type='default'
                    >
                        <DeleteOutlined
                            style={{
                                color: isDeletingActive ? token.colorPrimary : '',
                            }}
                        />
                    </Button>
                </>
            )}
            <Button size='large' shape='circle' onClick={zoomIn} type='default'>
                <ZoomInOutlined />
            </Button>
            <Button size='large' shape='circle' onClick={zoomOut} type='default'>
                <ZoomOutOutlined />
            </Button>
            <Button size='large' shape='circle' onClick={resetZoom} type='default'>
                <ReloadOutlined />
            </Button>
        </div>
    );
};

export default ActionButtons;
