import { useState, FC } from 'react';
import { useTranslate, IResourceComponentsProps, usePermissions } from '@refinedev/core';
import { Edit, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';

import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';

import 'react-mde/lib/styles/css/react-mde-all.css';

import { IFactory, IProject } from 'interfaces';
import { Roles } from 'interfaces/roles';
import { ProcessingStatus } from 'interfaces';

export const ProjectsEdit: FC<IResourceComponentsProps> = () => {
    const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write');

    const t = useTranslate();
    const { data: permissionsData } = usePermissions<string[]>();

    const { formProps, saveButtonProps } = useForm<IProject>();

    const { selectProps: factoriesSelectProps } = useSelect<IFactory>({
        resource: 'factories',
        optionLabel: 'name',
        optionValue: 'id',

        pagination: {
            mode: 'server',
        },
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                initialValues={{
                    ...formProps.initialValues,
                }}
                layout='vertical'
            >
                <Form.Item
                    label={t('projects.fields.name')}
                    name='name'
                    rules={[
                        {
                            required: true,
                        },
                        {
                            min: 1,
                            max: 50,
                        },
                        {
                            pattern: new RegExp('^[a-zA-Z0-9-_]*$'),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                {permissionsData?.includes(Roles.ADMIN) && (
                    <Form.Item
                        label={t('projects.fields.processingStatus')}
                        name='processingStatus'
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            placeholder={t('projects.selects.selectProcessingStatus')}
                            options={Object.values(ProcessingStatus).map((status) => ({
                                value: status,
                                label: status,
                            }))}
                        />
                    </Form.Item>
                )}
                <Form.Item
                    label={t('users.fields.factory')}
                    name='factoryId'
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select {...factoriesSelectProps} />
                </Form.Item>
                <Form.Item label={t('projects.fields.description')} name='description'>
                    <ReactMde
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={(markdown) =>
                            Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                        }
                    />
                </Form.Item>
            </Form>
        </Edit>
    );
};
