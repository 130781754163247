import { FC, useState } from 'react';

import {
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    HttpError,
    getDefaultFilter,
    usePermissions,
    useNavigation,
} from '@refinedev/core';

import {
    List,
    TextField,
    useTable,
    getDefaultSortOrder,
    DateField,
    EditButton,
    DeleteButton,
    // ShowButton,
    BooleanField,
    CreateButton,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons';

import {
    Table,
    Space, // useSelect,
    Select,
    Card,
    Col,
    Row,
    Button,
    DatePicker,
    Form,
    FormProps,
    Input,
    Popover,
    Checkbox,
} from 'antd';

import dayjs from 'dayjs';
import { IProject, IProjectFilterVariables, ProcessingStatus } from 'interfaces';
import { ProjectProcessingStatus } from 'components';
import { useMemo } from 'react';
import { Roles } from 'interfaces/roles';
import { DATE_FORMAT } from '../../constants';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

export const ProjectsList: FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { show } = useNavigation();
    const { data: permissionsData } = usePermissions<string[]>();

    const {
        tableProps,
        sorters: sorter,
        searchFormProps,
        filters,
    } = useTable<IProject, HttpError, IProjectFilterVariables>({
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { name, createdAt, processingStatus } = params;

            filters.push({
                field: 'name',
                operator: 'contains',
                value: name ? name : undefined,
            });

            filters.push({
                field: 'processingStatus',
                operator: 'in',
                value:
                    processingStatus && processingStatus.length > 0 ? processingStatus : undefined,
            });

            // filters.push({
            //   field: "user.id",
            //   operator: "eq",
            //   value: user,
            // });

            // filters.push({
            //   field: "status.text",
            //   operator: "in",
            //   value: status,
            // });

            filters.push(
                {
                    field: 'createdAt',
                    operator: 'gte',
                    value: createdAt ? createdAt[0].startOf('day').toISOString() : undefined,
                },
                {
                    field: 'createdAt',
                    operator: 'lte',
                    value: createdAt ? createdAt[1].endOf('day').toISOString() : undefined,
                }
            );

            return filters;
        },

        sorters: {
            initial: [
                {
                    field: 'createdAt',
                    order: 'desc',
                },
            ],
        },
    });

    // const userIds = tableProps?.dataSource?.map((item) => item.user.id) ?? [];
    // const { data: usersData, isLoading } = useMany<IUser>({
    //   resource: "users",
    //   ids: userIds,
    //   queryOptions: {
    //     enabled: userIds.length > 0,
    //   },
    // });

    // const { selectProps: categorySelectProps } = useSelect<IUser>({
    //   resource: "users",
    // });

    const defaultTableSettings = [
        t('projects.fields.name'),
        t('projects.fields.processingStatus'),
        t('projects.fields.createdAt'),
        t('projects.fields.updatedAt'),
        t('projects.fields.processingStartedAt'),
        t('projects.fields.processingFinishedAt'),
        t('projects.fields.deleted'),
    ];
    const getInitialTableSettings = () => {
        const item = localStorage.getItem('solar-panel-crm-projects-table-settings');
        if (item) return JSON.parse(item);
        return defaultTableSettings;
    };

    const handleChangeTableSettings = (val: CheckboxValueType[]) => {
        setTableSettings(val);
        localStorage.setItem('solar-panel-crm-projects-table-settings', JSON.stringify(val));
    };

    const [tableSetting, setTableSettings] = useState<CheckboxValueType[]>(
        getInitialTableSettings()
    );

    const tableSettingsViewContent = (
        <Checkbox.Group
            className='projects__table-settings'
            defaultValue={tableSetting}
            options={defaultTableSettings}
            onChange={handleChangeTableSettings}
        />
    );

    return (
        <Row gutter={[16, 16]}>
            <Col xl={5} lg={24} xs={24}>
                <Card bordered={false} title={t('projects.filter.title')}>
                    <Filter formProps={searchFormProps} filters={filters || []} />
                </Card>
            </Col>
            <Col xl={19} xs={24}>
                <List
                    headerButtons={() => (
                        <div
                            style={{
                                display: 'flex',
                                float: 'right',
                                marginBottom: 10,
                                gap: 5,
                            }}
                        >
                            {(permissionsData?.includes(Roles.ADMIN) ||
                                permissionsData?.includes(Roles.OPERATOR)) && <CreateButton />}
                            <Popover
                                content={tableSettingsViewContent}
                                title={t('table.settings')}
                                placement='bottom'
                            >
                                <Button style={{ float: 'right', marginBottom: 10 }}>
                                    <Icons.SettingOutlined />
                                    {t('table.settings')}
                                </Button>
                            </Popover>
                        </div>
                    )}
                    // pageHeaderProps={{
                    //   extra: <Actions />,
                    // }}
                >
                    <Table
                        {...tableProps}
                        rowKey='id'
                        onRow={(record) => {
                            return {
                                onClick: () => show('projects', record.id),
                            };
                        }}
                    >
                        {tableSetting.includes(t('projects.fields.name')) && (
                            <Table.Column
                                dataIndex='name'
                                key='name'
                                title={t('projects.fields.name')}
                                render={(value) => <TextField value={value} />}
                                defaultSortOrder={getDefaultSortOrder('title', sorter)}
                                sorter
                            />
                        )}
                        {tableSetting.includes(t('projects.fields.processingStatus')) && (
                            <Table.Column
                                dataIndex='processingStatus'
                                key='processingStatus'
                                title={t('projects.fields.processingStatus')}
                                // render={(value) => <TagField value={value} />}
                                render={(value) => {
                                    return <ProjectProcessingStatus status={value} />;
                                }}
                                defaultSortOrder={getDefaultSortOrder('processingStatus', sorter)}
                                sorter
                            />
                        )}
                        {tableSetting.includes(t('projects.fields.createdAt')) && (
                            <Table.Column
                                dataIndex='createdAt'
                                key='createdAt'
                                title={t('projects.fields.createdAt')}
                                render={(value) =>
                                    value ? (
                                        <DateField
                                            value={value ? value : ''}
                                            format={DATE_FORMAT}
                                        />
                                    ) : (
                                        '-'
                                    )
                                }
                                defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
                                sorter
                            />
                        )}
                        {tableSetting.includes(t('projects.fields.updatedAt')) && (
                            <Table.Column
                                dataIndex='updatedAt'
                                key='updatedAt'
                                title={t('projects.fields.updatedAt')}
                                render={(value) =>
                                    value ? (
                                        <DateField
                                            value={value ? value : ''}
                                            format={DATE_FORMAT}
                                        />
                                    ) : (
                                        '-'
                                    )
                                }
                                defaultSortOrder={getDefaultSortOrder('updatedAt', sorter)}
                                sorter
                            />
                        )}
                        {tableSetting.includes(t('projects.fields.processingStartedAt')) && (
                            <Table.Column
                                dataIndex='processingStartedAt'
                                key='processingStartedAt'
                                title={t('projects.fields.processingStartedAt')}
                                render={(value) =>
                                    value ? (
                                        <DateField
                                            value={value ? value : ''}
                                            format={DATE_FORMAT}
                                        />
                                    ) : (
                                        '-'
                                    )
                                }
                                defaultSortOrder={getDefaultSortOrder(
                                    'processingStartedAt',
                                    sorter
                                )}
                                sorter
                            />
                        )}
                        {tableSetting.includes(t('projects.fields.processingFinishedAt')) && (
                            <Table.Column
                                dataIndex='processingFinishedAt'
                                key='processingFinishedAt'
                                title={t('projects.fields.processingFinishedAt')}
                                render={(value) =>
                                    value ? (
                                        <DateField
                                            value={value ? value : ''}
                                            format={DATE_FORMAT}
                                        />
                                    ) : (
                                        '-'
                                    )
                                }
                                defaultSortOrder={getDefaultSortOrder(
                                    'processingFinishedAt',
                                    sorter
                                )}
                                sorter
                            />
                        )}
                        {/* <Table.Column
          dataIndex={["user", "created_by"]}
          key="created_by"
          title={t("projects.fields.created_by")}
          render={(value) => {
            if (isLoading) {
              return <TextField value={t("loading")} />;
            }
            return (
              <TextField
                value={
                  categoriesData?.data.find((item) => item.id === value)?.email
                }
              />
            );
          }}
          defaultSortOrder={getDefaultSortOrder("deleted", sorter)}
          sorter
        /> */}
                        {permissionsData?.includes(Roles.ADMIN) &&
                            tableSetting.includes(t('projects.fields.deleted')) && (
                                <Table.Column
                                    dataIndex='deleted'
                                    key='deleted'
                                    title={t('projects.fields.deleted')}
                                    render={(value) => {
                                        return <BooleanField value={value} />;
                                    }}
                                    defaultSortOrder={getDefaultSortOrder('deleted', sorter)}
                                    sorter
                                />
                            )}
                        <Table.Column<IProject>
                            title={t('table.actions')}
                            dataIndex='actions'
                            render={(_, record) => (
                                <Space onClick={(e) => e.stopPropagation()}>
                                    {/* <ShowButton hideText size='small' recordItemId={record.id} /> */}
                                    {(permissionsData?.includes(Roles.ADMIN) ||
                                        permissionsData?.includes(Roles.OPERATOR)) && (
                                        <EditButton
                                            hideText
                                            size='small'
                                            recordItemId={record.id}
                                        />
                                    )}
                                    {(permissionsData?.includes(Roles.ADMIN) ||
                                        permissionsData?.includes(Roles.OPERATOR)) && (
                                        <DeleteButton
                                            hideText
                                            size='small'
                                            recordItemId={record.id}
                                        />
                                    )}
                                </Space>
                            )}
                        />
                    </Table>
                </List>
            </Col>
        </Row>
    );
};

const Filter: FC<{ formProps: FormProps; filters: CrudFilters }> = (props) => {
    const t = useTranslate();

    const { formProps, filters } = props;
    // const { selectProps: projectsSelectProps } = useSelect<IProject>({
    //     resource: 'projects',
    //     defaultValue: getDefaultFilter('project.id', filters),
    // });

    const { RangePicker } = DatePicker;

    const created_date = useMemo(() => {
        const start = getDefaultFilter('createdAt', filters, 'gte');
        const end = getDefaultFilter('createdAt', filters, 'lte');

        const startFrom = dayjs(start);
        const endAt = dayjs(end);

        if (start && end) {
            return [startFrom, endAt];
        }
        return undefined;
    }, [filters]);

    return (
        <Form
            layout='vertical'
            {...formProps}
            initialValues={{
                name: getDefaultFilter('name', filters, 'in'),
                // store: getDefaultFilter("store.id", filters),
                // user: getDefaultFilter("user.id", filters),
                processingStatus: getDefaultFilter('status.text', filters, 'or'),
                created_date,
            }}
        >
            <Row gutter={[10, 0]} align='bottom'>
                <Col xl={24} md={8} sm={12} xs={24}>
                    <Form.Item label={t('projects.filter.search.label')} name='name'>
                        <Input
                            placeholder={t('projects.filter.search.placeholder')}
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col xl={24} md={8} sm={12} xs={24}>
                    <Form.Item label={t('projects.filter.status.label')} name='processingStatus'>
                        <Select
                            options={Object.values(ProcessingStatus).map((status) => ({
                                value: status,
                                label: status.replaceAll('_', ' '),
                            }))}
                            allowClear
                            mode='tags'
                            placeholder={t('projects.filter.status.placeholder')}
                        />
                    </Form.Item>
                </Col>
                <Col xl={24} md={8} sm={12} xs={24}>
                    <Form.Item label={t('projects.filter.createdAt')} name='createdAt'>
                        <RangePicker style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col xl={24} md={8} sm={12} xs={24}>
                    <Form.Item>
                        <Button htmlType='submit' type='primary' size='large' block>
                            {t('projects.filter.submit')}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
