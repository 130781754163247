import { Show } from '@refinedev/antd';
import { Button, Anchor, Row, Col, Typography } from 'antd';
import { useNavigation } from '@refinedev/core';
import { AppleOutlined, DownloadOutlined, WindowsOutlined } from '@ant-design/icons';
import { DIST_ROOT } from '../../constants';

const { Paragraph, Title } = Typography;

interface LabelProps {
    name: string;
}

const Label: React.FC<LabelProps> = ({ name }) => (
    <div id={name}>
        <Title level={3}>
            <a aria-hidden='true' href={`#${name}`}>
                <span className='material-symbols-outlined'>tag</span>
            </a>
            {name}
        </Title>
    </div>
);

export const HelpPage: React.FC = () => {
    const navigate = useNavigation();

    return (
        <Show
            title='FAQ'
            headerProps={{
                backIcon: false,
            }}
            headerButtons={() => (
                <Button type='default' onClick={() => navigate.push('/')}>
                    Go back
                </Button>
            )}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={18}>
                    <div id='installation-instructions'>
                        <>
                            <Label name='Installation instructions' />
                            <div id='faq-item-content'>
                                <Paragraph>
                                    The latest version of the desktop application is below.
                                </Paragraph>
                                <Paragraph>
                                    <WindowsOutlined /> For Windows
                                </Paragraph>
                                <Button
                                    style={{ marginBottom: 16 }}
                                    type='primary'
                                    icon={<DownloadOutlined />}
                                    size='middle'
                                    href={`${DIST_ROOT}/SolarPanels-x64-latest.exe`}
                                >
                                    Download:
                                </Button>
                                <Paragraph>
                                    <AppleOutlined /> For Mac OS
                                </Paragraph>
                                <Button
                                    style={{ marginBottom: 16 }}
                                    type='primary'
                                    icon={<DownloadOutlined />}
                                    size='middle'
                                    href={`${DIST_ROOT}/SolarPanels-mac-latest.dmg`}
                                >
                                    Download
                                </Button>
                                <Paragraph>
                                    For the correct work please, follow the next steps:
                                </Paragraph>
                                <ol>
                                    <li>
                                        Delete the old version of the desktop application from the
                                        PC if previously it was installed
                                    </li>
                                    <li>Install the latest version of the desktop application</li>
                                </ol>
                            </div>
                        </>
                    </div>
                    <div id='logs'>
                        <Label name='Logs' />
                        <div id='faq-item-content'>
                            <Paragraph>
                                If you have any issues or errors during the desktop application work
                                please, follow the next steps:
                            </Paragraph>
                            <ol>
                                <li>Press in the menu button “Help”</li>
                                <li>Choose In the dropdown list “Open logs dir”</li>
                                <li>
                                    {' '}
                                    In the folder copy the file “sp” and send it with the described
                                    issue to the development team on the email{' '}
                                    <a href='mailto:sstopkin@issart.com'>sstopkin@issart.com</a>,
                                    also please add in the CC email{' '}
                                    <a href='mailto:ppushkareva@issart.com'>
                                        ppushkareva@issart.com
                                    </a>{' '}
                                    and{' '}
                                    <a href='mailto:vpesotskiy@issart.com'>vpesotskiy@issart.com</a>
                                    .
                                </li>
                            </ol>
                        </div>
                    </div>
                </Col>
                <Col xs={0} sm={6}>
                    <Anchor
                        items={[
                            {
                                key: 'installation-instructions',
                                href: '#installation-instructions',
                                title: 'Installation instructions',
                            },
                            {
                                key: 'logs',
                                href: '#logs',
                                title: 'Logs',
                            },
                        ]}
                    />
                </Col>
            </Row>
        </Show>
    );
};
