import React, { useState } from 'react';
import { useTranslate, IResourceComponentsProps } from '@refinedev/core';
import { Edit, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';

import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';

import 'react-mde/lib/styles/css/react-mde-all.css';

import { IFactory } from 'interfaces';

export const FactoriesEdit: React.FC<IResourceComponentsProps> = () => {
    const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write');

    const t = useTranslate();

    const { formProps, saveButtonProps } = useForm<IFactory>();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                initialValues={{
                    id: formProps.id,
                    ...formProps.initialValues,
                }}
                layout='vertical'
            >
                <Form.Item
                    label={t('factories.fields.name')}
                    name='name'
                    rules={[
                        {
                            required: true,
                        },
                        {
                            min: 1,
                            max: 50,
                        },
                        {
                            pattern: new RegExp('^[a-zA-Z0-9-_]*$'),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item label={t('factories.fields.description')} name='description'>
                    <ReactMde
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={(markdown) =>
                            Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                        }
                    />
                </Form.Item>
            </Form>
        </Edit>
    );
};
