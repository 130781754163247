import { FC } from 'react';
import { useNavigation, useTranslate } from '@refinedev/core';
import { useSimpleList } from '@refinedev/antd';
import { List as AntdList, Timeline, Tooltip, Typography } from 'antd';
import { IProjectStatus } from '../../interfaces';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { getProjectBorderColor, getProjectStatusColor } from '../../utils';
import './styles.less';

dayjs.extend(relativeTime);

const ProjectsTimeline: FC = () => {
    const t = useTranslate();
    const { show } = useNavigation();

    const //`useSimpleList` does not accept all of Ant Design's `List` component props anymore. You can directly use `List` component instead.,
        { listProps } = useSimpleList<IProjectStatus>({
            resource: 'statistics/projects-statuses-timeline',
            initialSorter: [{ field: 'createdAt', order: 'desc' }],
            pagination: {
                pageSize: 6,
            },
            syncWithLocation: false,
        });

    const { dataSource } = listProps;

    const { Text } = Typography;

    return (
        <AntdList {...listProps}>
            {dataSource?.map(({ createdAt, projectId, projectName, processingStatus, id }) => (
                <Timeline.Item
                    className='timeline__point'
                    key={id}
                    style={{
                        border: `0.25px solid ${getProjectBorderColor(processingStatus)}`,
                        borderRadius: 7.5,
                    }}
                    color={getProjectStatusColor(processingStatus)}
                >
                    <div
                        style={{
                            backgroundColor: getProjectStatusColor(processingStatus),
                        }}
                        className={`timeline`}
                    >
                        <Tooltip
                            overlayInnerStyle={{ color: '#626262' }}
                            color='rgba(255, 255, 255, 0.3)'
                            placement='topLeft'
                            title={dayjs(createdAt).format('DD/MM/YY HH:mm:ss')}
                        >
                            <Text italic className='createdAt'>
                                {dayjs(createdAt).fromNow()}
                            </Text>
                        </Tooltip>
                        <Text>{t(`enum.processingStatuses.${processingStatus}`)}</Text>
                        <Text onClick={() => show('projects', projectId)} strong className='number'>
                            #{projectName}
                        </Text>
                    </div>
                </Timeline.Item>
            ))}
        </AntdList>
    );
};

export default ProjectsTimeline;
