import { useGetLocale, useSetLocale, useGetIdentity, useNavigation } from '@refinedev/core';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons';

import {
    Menu,
    Dropdown,
    Avatar,
    Typography,
    Space,
    Grid,
    Row,
    Col,
    Layout as AntdLayout,
    Button,
} from 'antd';

import { useTranslation } from 'react-i18next';
// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Header: AntdHeader } = AntdLayout;
const { DownOutlined } = Icons;
const { Text } = Typography;
const { useBreakpoint } = Grid;

export const Header: React.FC = () => {
    const { i18n } = useTranslation();
    const locale = useGetLocale();
    const changeLanguage = useSetLocale();
    const { data: user } = useGetIdentity<{ id: string; name: string }>();
    const screens = useBreakpoint();
    const navigate = useNavigation();

    const currentLocale = locale();

    const menu = (
        <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
            {[...(i18n.languages ?? [])]
                .sort()
                .filter((lang: string) => lang === 'en')
                .map((lang: string) => (
                    <Menu.Item
                        key={lang}
                        onClick={() => changeLanguage(lang)}
                        icon={
                            <span style={{ marginRight: 8 }}>
                                <Avatar size={16} src={`/images/flags/${lang}.svg`} />
                            </span>
                        }
                    >
                        {/* {lang === "en" ? "English" : "German"} */}
                        English
                    </Menu.Item>
                ))}
        </Menu>
    );

    return (
        <AntdHeader
            style={{
                padding: '0 24px',
                background: 'white',
            }}
        >
            <Row
                align='middle'
                style={{
                    justifyContent: screens.sm ? 'space-between' : 'end',
                }}
            >
                <Col xs={0} sm={12}></Col>
                <Col>
                    <Space size='middle' align='center'>
                        <Button
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            type='default'
                            icon={<QuestionCircleOutlined />}
                            onClick={() => {
                                navigate.push('/help');
                            }}
                        />
                        <Dropdown overlay={menu}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                            <a style={{ color: 'inherit' }} onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
                                    <div
                                        style={{
                                            display: screens.lg ? 'block' : 'none',
                                        }}
                                    >
                                        {/* {currentLocale === "en" ? "English" : "German"}*/}
                                        English
                                        <DownOutlined
                                            style={{
                                                fontSize: '12px',
                                                marginLeft: '6px',
                                            }}
                                        />
                                    </div>
                                </Space>
                            </a>
                        </Dropdown>

                        <Text
                            ellipsis
                            strong
                            style={{
                                display: 'flex',
                            }}
                        >
                            {user?.name}
                        </Text>
                    </Space>
                </Col>
            </Row>
        </AntdHeader>
    );
};
