import { Slider, Typography } from 'antd';
import styles from '../style.module.less';
import { FC } from 'react';

const { Text } = Typography;

interface ChangeBrightnessContrastProps {
    option: string;
    onChangeBrightness: (value: number) => void;
    onChangeContrast: (value: number) => void;
}

const ChangeBrightnessContrast: FC<ChangeBrightnessContrastProps> = ({
    option,
    onChangeBrightness: changeBrightness,
    onChangeContrast: changeContrast,
}) => {
    return (
        <div
            className={styles['brightness-contrast-wrapper']}
            style={{
                visibility: option === 'Brightness/Contrast' ? 'visible' : 'hidden',
            }}
        >
            <div className={styles['brighness-contarst-setter']}>
                <Text>Brightness: </Text>
                <Slider
                    style={{ width: 200 }}
                    min={-100}
                    max={100}
                    step={1}
                    defaultValue={0}
                    onChange={changeBrightness}
                />
            </div>
            <div className={styles['brighness-contarst-setter']}>
                <Text>Contrast: </Text>
                <Slider
                    style={{ width: 200 }}
                    min={-100}
                    max={100}
                    step={1}
                    defaultValue={0}
                    onChange={changeContrast}
                />
            </div>
        </div>
    );
};

export default ChangeBrightnessContrast;
