import React from 'react';
import routerProvider from '@refinedev/react-router-v6/legacy';
import { Logo } from './styled';

const { Link } = routerProvider;

type TitleProps = {
    collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
    return (
        <Link to={'/'}>
            <Logo>
                {collapsed ? (
                    <img
                        src={'/sinovoltaics-logo-collapsed.svg'}
                        alt='Logo'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '12px 24px',
                            marginTop: '12px',
                        }}
                    />
                ) : (
                    <img
                        src={'/sinovoltaics-logo-full.webp'}
                        alt='Logo'
                        style={{
                            width: '200px',
                            padding: '12px 24px',
                            marginTop: '12px',
                        }}
                    />
                )}
            </Logo>
        </Link>
    );
};
