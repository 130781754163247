import { Dayjs } from 'dayjs';

export enum ProcessingStatus {
    CREATED = 'CREATED',
    UPLOADING = 'UPLOADING',
    UPLOADING_ERROR = 'UPLOADING_ERROR',
    READY_FOR_PROCESSING = 'READY_FOR_PROCESSING',
    PENDING_PROCESSING = 'PENDING_PROCESSING',
    STARTING = 'STARTING',
    PROCESSING = 'PROCESSING',
    STOPPING = 'STOPPING',
    PROCESSING_ERROR = 'PROCESSING_ERROR',
    PARTIALLY_PROCESSED = 'PARTIALLY_PROCESSED',
    DONE = 'DONE',
    ERROR = 'ERROR',
}

export interface ICategory {
    id: string;
    title: string;
}

export interface ParamsTypes {
    projectID: string;
    fileID: string;
}

export interface IUser {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    createdAt: Date;
    updatedAt: Date;
    isActive: boolean;
    password: string;
    role: 'admin' | 'operator' | 'partner';
    factories: Array<IFactory>;
    refreshToken: string;
    description: string;
}

export interface IFile {
    id: string;
    fileName: string;
    createdAt: Date;
    projectId: IProject;
    originalFileUrl: string;
    processedFileUrl: string;
    processingResult: { defects: Array<{ defectId: number; amount: number }> };
    isUploaded: boolean;
}

export interface IFactory {
    id: string;
    name: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    deteled: boolean;
}

export interface IArchive {
    id: string;
    archiveType: string;
    projectId: string;
    isCompleted: boolean;
    createdAt: Date;
    completedAt: Date;
    files: Array<IArchiveFile>;
}

export interface IArchiveFile {
    archiveId: string;
    completedAt: string;
    createdAt: string;
    fileName: string;
    id: string;
    isCompleted: boolean;
    url: string;
}

export interface IProject {
    id: string;
    name: string;
    processingStatus: ProcessingStatus;
    description: string;
    createdAt: string;
    updatedAt: string;
    processingStartedAt: string;
    processingFinishedAt: string;
    factoryId: IFactory;
    userId: IUser;
    deteled: boolean;
}

export interface IProjectFilterVariables {
    name?: string;
    // store?: string;
    // user?: string;
    createdAt?: [Dayjs, Dayjs];
    processingStatus?: string;
}

export interface IProjectStatus {
    id: string;
    projectId: string;
    projectName: string;
    processingStatus: ProcessingStatus;
    createdAt: string;
}
