export const PROFILE = 'profile';
export const ACCESS_TOKEN_KEY = 'sp-access-token';
export const REFRESH_TOKEN_KEY = 'sp-refresh-token';
export const ACCESS_TOKEN_EXPIRES_AT = 'sp-access-token-expires-at';
export const REFRESH_TOKEN_EXPIRES_AT = 'sp-refresh-token-expires-at';
export const API_ROOT = `${
    process.env.REACT_APP_BACKEND_URL || 'https://api-test.selma.sinovoltaics.com'
}/api/v1`;
export const DIST_ROOT = `${
    process.env.REACT_APP_DIST_URL || 'https://dist-test.selma.sinovoltaics.com'
}`;
export const DATE_FORMAT = 'YYYY-MM-DD HH:mm';
