import { FC } from 'react';
import { Row, Col, Card, Typography, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Icons from '@ant-design/icons';
import ProjectQueueTimeLine from '../../components/ProjectsQueueTimeLine';

const { Text } = Typography;

export const ProjectsQueuePage: FC = () => {
    const { t } = useTranslation();

    return (
        <Row gutter={[16, 16]}>
            <Col xs={12}>
                <Card
                    title={
                        <Text strong>
                            {t('projectsQueue.timeline.title')}
                            <Tooltip placement='right' title={t('projectsQueue.tooltip.text')}>
                                <Icons.InfoCircleOutlined style={{ fontSize: 14, marginLeft: 4 }} />
                            </Tooltip>
                        </Text>
                    }
                >
                    <ProjectQueueTimeLine />
                </Card>
            </Col>
        </Row>
    );
};
