import { Authenticated, CanAccess, Refine } from '@refinedev/core';
import { notificationProvider, ErrorComponent, ThemedLayoutV2 } from '@refinedev/antd';

import * as Icons from '@ant-design/icons';

import '@refinedev/antd/dist/reset.css';
import routerProvider from '@refinedev/react-router-v6';
import dataProvider from '@refinedev/nestjsx-crud';
import { authProvider } from './authProvider';
import { UsersList, UsersCreate, UsersEdit, UsersShow } from 'pages/users';
import { Header, OffLayoutArea, Title, Footer } from 'components';
import { ThemedSiderV2 } from 'components/themedLayout/sider';
import { useTranslation } from 'react-i18next';
import { FactoriesList, FactoriesCreate, FactoriesEdit, FactoriesShow } from 'pages/factories';
import { ProjectsList, ProjectsCreate, ProjectsEdit, ProjectsShow } from 'pages/projects';
import { ArchivesList, ArchivesShow } from 'pages/archives';
import { AuditLogsList, AuditLogsShow } from 'pages/auditLogs';
import { ViewerShow } from 'pages/viewer';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import * as constants from './constants';
import { DashboardPage } from 'pages/dashboard';
import { ProjectsQueuePage } from 'pages/queue';
import { LoginPage } from 'pages/login';
import { HelpPage } from 'pages/help';
import { ChangePasswordPage } from 'pages/changePassword';
import './App.less';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { NavigateToResource } from '@refinedev/react-router-v6';

export const axiosInstance: AxiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    // Here we can perform any function we'd like on the request
    (request: AxiosRequestConfig) => {
        // Retrieve the token from local storage
        const token = localStorage.getItem(constants.ACCESS_TOKEN_KEY);
        // Check if the header property exists
        if (request.headers) {
            // Set the Authorization header if it exists
            request.headers['Authorization'] = `Bearer ${token}`;
        } else {
            // Create the headers property if it does not exist
            request.headers = {
                Authorization: `Bearer ${token}`,
            };
        }

        return request;
    }
);

function App() {
    const { t, i18n } = useTranslation();

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    return (
        <BrowserRouter>
            <Refine
                options={{
                    disableTelemetry: true,
                    warnWhenUnsavedChanges: true,
                }}
                notificationProvider={notificationProvider}
                routerProvider={routerProvider}
                dataProvider={dataProvider(`${constants.API_ROOT}`, axiosInstance)}
                authProvider={authProvider}
                accessControlProvider={{
                    can: async ({ action, resource }) => {
                        let can = true;

                        const profile = localStorage.getItem('profile');
                        if (!profile) {
                            throw new Error('Error getting profile from local storage!');
                        }
                        const role = JSON.parse(profile).role;

                        if (role !== 'admin') {
                            const forbiddenPages = ['users', 'auditlogs', 'queue'];

                            if (resource) {
                                if (forbiddenPages.includes(resource.toLocaleLowerCase())) {
                                    can = false;
                                }
                            }

                            if (resource === 'archives') {
                                if (action === 'show') {
                                    can = true;
                                } else {
                                    can = false;
                                }
                            }
                        }

                        return Promise.resolve({ can });
                    },
                }}
                resources={[
                    {
                        name: 'dashboard',
                        list: '/',
                        meta: { icon: <Icons.DashboardOutlined /> },
                    },
                    {
                        name: 'users',
                        list: '/users',
                        create: '/users/create',
                        edit: '/users/edit/:id',
                        show: '/users/show/:id',
                        meta: {
                            icon: <Icons.UserOutlined />,
                        },
                    },
                    {
                        name: 'factories',
                        list: '/factories',
                        create: '/factories/create',
                        edit: '/factories/edit/:id',
                        show: '/factories/show/:id',
                        meta: {
                            icon: <Icons.BankOutlined />,
                        },
                    },
                    {
                        name: 'projects',
                        list: '/projects',
                        create: '/projects/create',
                        edit: '/projects/edit/:id',
                        show: '/projects/show/:id',
                        meta: {
                            icon: <Icons.ProjectOutlined />,
                        },
                    },
                    {
                        name: 'queue',
                        list: '/queue',
                        meta: { icon: <Icons.SwitcherOutlined /> },
                    },
                    {
                        name: 'archives',
                        list: '/archives',
                        show: '/archives/show/:id',
                        meta: {
                            icon: <Icons.BankOutlined />,
                        },
                    },
                    {
                        name: 'auditLogs',
                        list: '/auditLogs',
                        meta: {
                            icon: <Icons.ProjectOutlined />,
                        },
                    },
                ]}
                i18nProvider={i18nProvider}
            >
                <Routes>
                    <Route
                        element={
                            <Authenticated>
                                <ThemedLayoutV2
                                    Title={Title}
                                    Header={Header}
                                    Footer={Footer}
                                    Sider={ThemedSiderV2}
                                    OffLayoutArea={OffLayoutArea}
                                >
                                    <Outlet />
                                </ThemedLayoutV2>
                            </Authenticated>
                        }
                    >
                        <Route index element={<DashboardPage />} />
                        <Route path='/queue'>
                            <Route index element={<ProjectsQueuePage />} />
                        </Route>
                        <Route path='/projects'>
                            <Route index element={<ProjectsList />} />
                            <Route path='create' element={<ProjectsCreate />} />
                            <Route path='edit/:id' element={<ProjectsEdit />} />
                            <Route path='show/:id' element={<ProjectsShow />} />
                        </Route>
                        <Route path='/archives'>
                            <Route index element={<ArchivesList />} />
                            <Route path='show/:id' element={<ArchivesShow />} />
                        </Route>
                        <Route path='/factories'>
                            <Route index element={<FactoriesList />} />
                            <Route path='create' element={<FactoriesCreate />} />
                            <Route path='edit/:id' element={<FactoriesEdit />} />
                            <Route path='show/:id' element={<FactoriesShow />} />
                        </Route>
                        <Route path='/users'>
                            <Route index element={<UsersList />} />
                            <Route path='create' element={<UsersCreate />} />
                            <Route path='edit/:id' element={<UsersEdit />} />
                            <Route path='show/:id' element={<UsersShow />} />
                        </Route>

                        {
                            <Route path='/auditLogs'>
                                <Route
                                    index
                                    element={
                                        <CanAccess
                                            fallback={<NavigateToResource resource='dashboard' />}
                                        >
                                            <AuditLogsList />
                                        </CanAccess>
                                    }
                                />
                                <Route
                                    path='show/:id'
                                    element={
                                        <CanAccess
                                            fallback={<NavigateToResource resource='dashboard' />}
                                        >
                                            <AuditLogsShow />
                                        </CanAccess>
                                    }
                                />
                            </Route>
                        }
                        <Route path='/viewer/show/:projectID/:fileID' element={<ViewerShow />} />
                        <Route path='/help' element={<HelpPage />} />
                    </Route>
                    <Route
                        element={
                            <Authenticated fallback={<Outlet />}>
                                <NavigateToResource resource='dashboard' />
                            </Authenticated>
                        }
                    >
                        <Route path='/login' element={<LoginPage />} />
                    </Route>
                    <Route path='/change-password' element={<ChangePasswordPage />} />
                    <Route path='*' element={<ErrorComponent />} />
                </Routes>
            </Refine>
        </BrowserRouter>
    );
}

export default App;
