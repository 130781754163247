import { FC, useState } from 'react';

import {
    useTranslate,
    IResourceComponentsProps,
    useOne,
    useShow,
    useUpdate,
    useCustom,
    usePermissions,
    useNotification,
    useNavigation,
} from '@refinedev/core';

import { Show, MarkdownField, ShowButton, BooleanField, DateField } from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons';

import { Typography, Table, Button, Space, Row, Col, Modal } from 'antd';

import { IFactory, IFile, IProject, IUser, ProcessingStatus } from 'interfaces';
import { ProjectProcessingStatus } from 'components';
import { API_ROOT, DATE_FORMAT } from '../../constants';
import FileOutlined from '@ant-design/icons/lib/icons/FileOutlined';
import React from 'react';
import CompareSliderModal from 'components/CompareSlider';
import Preprocesing from 'components/Preprocessing';
import { Roles } from 'interfaces/roles';
// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import { FileMarkdownOutlined } from '@ant-design/icons';
import { axiosInstance } from '../../App';

const { Title } = Typography;

const initialProcessingComfirmationModalConfig = {
    open: false,
    loading: false,
    content: '',
    status: '',
};

export const ProjectsShow: FC<IResourceComponentsProps> = () => {
    const { open } = useNotification();
    const { show } = useNavigation();

    const [showPreprocessing, setShowPreprocessing] = useState<boolean>(false);
    const [showCompareSliderModal, setShowCompareSliderModal] = React.useState<boolean>(false);
    const [recordToShow, setRecordToShow] = React.useState<undefined | IFile>(undefined);
    const [isDownloadModalOpen, setIsDownloadModalOpen] = React.useState<boolean>(false);
    const [processingComfirmationModalConfig, setProcessingComfirmationModalConfig] =
        React.useState(initialProcessingComfirmationModalConfig);

    const handleShowCompareSliderModal = (record: IFile) => {
        setShowCompareSliderModal(true);
        setRecordToShow(record);
    };

    const handleCloseCompareSliderModal = () => {
        setShowCompareSliderModal(false);
        setRecordToShow(undefined);
    };

    const handleDownloadModalOpen = () => {
        setIsDownloadModalOpen(true);
    };

    const handleDownloadModalClose = () => {
        setIsDownloadModalOpen(false);
    };

    const handleDownloadImages = () => {
        //Here will be api call to get link
        axiosInstance
            .post(`${API_ROOT}/archives`, null, {
                params: {
                    projectId: record?.id,
                },
            })
            .then((response) => {
                if (response.data.isCompleted) {
                    // open?.({
                    //     message: t("archives.notifications.success"),
                    //     type: 'success'
                    // })
                    show('archives', response.data.id);
                } else {
                    open?.({
                        message: t('archives.notifications.inProgress'),
                        type: 'error',
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                open?.({
                    message: t('archives.notifications.error'),
                    type: 'error',
                });
            })
            .finally(() => {
                setIsDownloadModalOpen(false);
            });
    };

    const handleStartProcessingProject = () => {
        setProcessingComfirmationModalConfig({
            ...processingComfirmationModalConfig,
            open: true,
            status: ProcessingStatus.PROCESSING,
            content: t('projects.modal.startProcessingConfirm'),
        });
    };

    const handleStopProcessingProject = () => {
        setProcessingComfirmationModalConfig({
            ...processingComfirmationModalConfig,
            open: true,
            status: ProcessingStatus.STOPPING,
            content: t('projects.modal.stopProcessingConfirm'),
        });
    };

    const handleProcessingConfirmModalOk = () => {
        if (record) {
            setProcessingComfirmationModalConfig({
                ...processingComfirmationModalConfig,
                loading: true,
            });
            mutate(
                {
                    resource: 'projects',
                    id: record?.id.toString(),
                    values: {
                        processingStatus: processingComfirmationModalConfig.status,
                    },
                },
                {
                    onSuccess: () => {
                        setProcessingComfirmationModalConfig(
                            initialProcessingComfirmationModalConfig
                        );
                    },
                    onError: () => {
                        setProcessingComfirmationModalConfig(
                            initialProcessingComfirmationModalConfig
                        );
                    },
                }
            );
        }
    };

    const handleProcessingConfirmModalCancel = () => {
        setProcessingComfirmationModalConfig(initialProcessingComfirmationModalConfig);
    };
    const t = useTranslate();

    const { data: permissionsData } = usePermissions<string[]>();

    const { queryResult } = useShow<IProject>();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const { mutate } = useUpdate();

    const { data: factoryInfo } = useOne<IFactory>({
        resource: 'factories',
        id: (record?.factoryId as any) ?? '',
    });

    const { data: createdByInfo } = useOne<IUser>({
        resource: 'users',
        id: (record?.userId as any) ?? '',
    });

    const { data: projectFiles } = useCustom<any>({
        url: `${API_ROOT}/files`,
        method: 'get',
        queryOptions: {
            enabled: record?.id ? true : false,
        },
        config: {
            query: {
                projectId: record?.id,
            },
        },
    });

    const { data: dataDefects } = useCustom<any>({
        url: `${API_ROOT}/defects`,
        method: 'get',
    });
    const defectsMapping = dataDefects?.data;

    const filesData = projectFiles?.data.data.map((files: IFile) => ({
        ...files,
    }));

    return (
        <>
            <Show
                isLoading={isLoading}
                canEdit={
                    permissionsData?.includes(Roles.ADMIN) ||
                    permissionsData?.includes(Roles.OPERATOR)
                }
                headerButtons={({ defaultButtons }) => (
                    <>
                        {record?.processingStatus === ProcessingStatus.READY_FOR_PROCESSING && (
                            <Button onClick={() => setShowPreprocessing(true)}>
                                {t('projects.buttons.setPreprocessingOptions')}
                            </Button>
                        )}
                        {defaultButtons}
                    </>
                )}
            >
                <Row gutter={[16, 16]}>
                    <Col xl={5} lg={24} xs={24}>
                        <Title level={4}>{t('projects.fields.name')}</Title>
                        <Typography.Text>{record?.name}</Typography.Text>

                        <Title level={5}>{t('projects.fields.id')}</Title>
                        <Typography.Text>({record?.id})</Typography.Text>
                        {(permissionsData?.includes(Roles.ADMIN) ||
                            permissionsData?.includes(Roles.OPERATOR)) && (
                            <Title
                                style={{
                                    display:
                                        record?.processingStatus === ProcessingStatus.PROCESSING ||
                                        record?.processingStatus ===
                                            ProcessingStatus.READY_FOR_PROCESSING ||
                                        record?.processingStatus ===
                                            ProcessingStatus.PARTIALLY_PROCESSED
                                            ? ''
                                            : 'none',
                                }}
                                level={5}
                            >
                                {t('projects.fields.actions')}
                            </Title>
                        )}
                        {(permissionsData?.includes(Roles.ADMIN) ||
                            permissionsData?.includes(Roles.OPERATOR)) && (
                            <Button
                                style={{
                                    display:
                                        record?.processingStatus ===
                                            ProcessingStatus.READY_FOR_PROCESSING ||
                                        record?.processingStatus ===
                                            ProcessingStatus.PARTIALLY_PROCESSED
                                            ? ''
                                            : 'none',
                                }}
                                disabled={
                                    !(
                                        record?.processingStatus ===
                                            ProcessingStatus.READY_FOR_PROCESSING ||
                                        record?.processingStatus ===
                                            ProcessingStatus.PARTIALLY_PROCESSED
                                    )
                                }
                                key='accept'
                                icon={<Icons.CheckCircleOutlined />}
                                type='primary'
                                onClick={handleStartProcessingProject}
                            >
                                {t('projects.buttons.start')}
                            </Button>
                        )}
                        {(permissionsData?.includes(Roles.ADMIN) ||
                            permissionsData?.includes(Roles.OPERATOR)) && (
                            <Button
                                style={{
                                    display:
                                        record?.processingStatus === ProcessingStatus.PROCESSING
                                            ? ''
                                            : 'none',
                                }}
                                disabled={record?.processingStatus !== ProcessingStatus.PROCESSING}
                                key='reject'
                                danger
                                icon={<Icons.CloseCircleOutlined />}
                                type='primary'
                                onClick={handleStopProcessingProject}
                            >
                                {t('projects.buttons.stop')}
                            </Button>
                        )}
                        <Title level={5}>{t('projects.fields.processingStatus')}</Title>
                        <Typography.Text>
                            <ProjectProcessingStatus status={record?.processingStatus} />
                        </Typography.Text>

                        <Title level={5}>{t('projects.fields.factory')}</Title>
                        <Typography.Text>{factoryInfo?.data.name}</Typography.Text>

                        <Title level={5}>{t('projects.fields.createdBy')}</Title>
                        <Typography.Text>
                            {createdByInfo?.data.firstName} {createdByInfo?.data.lastName}
                        </Typography.Text>

                        <Title
                            style={{
                                display:
                                    record?.processingStatus === ProcessingStatus.DONE ||
                                    record?.processingStatus ===
                                        ProcessingStatus.PARTIALLY_PROCESSED
                                        ? ''
                                        : 'none',
                            }}
                            level={5}
                        >
                            {t('projects.fields.report')}
                        </Title>
                        <Button
                            style={{
                                display:
                                    record?.processingStatus === ProcessingStatus.DONE ||
                                    record?.processingStatus ===
                                        ProcessingStatus.PARTIALLY_PROCESSED
                                        ? ''
                                        : 'none',
                                marginBottom: '1.2em',
                            }}
                            key='download'
                            icon={<FileOutlined />}
                            type='default'
                            href={`${API_ROOT}/reports?projectId=${record?.id}`}
                        >
                            {t('projects.buttons.downloadReport')}
                        </Button>
                        <Button
                            style={{
                                display:
                                    record?.processingStatus === ProcessingStatus.DONE ||
                                    record?.processingStatus ===
                                        ProcessingStatus.PARTIALLY_PROCESSED
                                        ? ''
                                        : 'none',
                            }}
                            icon={<Icons.DownloadOutlined />}
                            type='default'
                            onClick={handleDownloadModalOpen}
                        >
                            {t('projects.buttons.downloadImages')}
                        </Button>
                        <Title level={5}>{t('projects.fields.description')}</Title>
                        <Typography.Text>
                            <MarkdownField value={record?.description} />
                        </Typography.Text>
                    </Col>
                    <Col xl={19} xs={24}>
                        <Title level={5}>{t('projects.fields.files')}</Title>
                        <Table
                            pagination={{
                                ...filesData?.pagination,
                                showSizeChanger: true,
                            }}
                            dataSource={filesData}
                            rowKey='id'
                        >
                            <Table.Column
                                dataIndex='fileName'
                                key='fileName'
                                title={t('files.fields.fileName')}
                            />
                            <Table.Column dataIndex='id' key='id' title={t('files.fields.id')} />
                            <Table.Column
                                dataIndex='createdAt'
                                key='createdAt'
                                title={t('files.fields.createdAt')}
                                render={(value) =>
                                    value ? (
                                        <DateField
                                            value={value ? value : ''}
                                            format={DATE_FORMAT}
                                        />
                                    ) : (
                                        '-'
                                    )
                                }
                            />
                            <Table.Column
                                dataIndex='isUploaded'
                                title={t('files.fields.isUploaded')}
                                render={(value) => {
                                    return <BooleanField value={value} />;
                                }}
                            />
                            <Table.Column<IFile>
                                title={t('table.actions')}
                                dataIndex='actions'
                                render={(_, record) => (
                                    <Space>
                                        {record.isUploaded && (
                                            <ShowButton
                                                onClick={() => handleShowCompareSliderModal(record)}
                                                hideText
                                                size='small'
                                                recordItemId={record.id}
                                            />
                                        )}

                                        {/* {(permissionsData?.includes(Roles.ADMIN) || permissionsData?.includes(Roles.OPERATOR)) && (
                <EditButton hideText size="small" recordItemId={record.id} />
              )}
              {(permissionsData?.includes(Roles.ADMIN) || permissionsData?.includes(Roles.OPERATOR)) && (
                <DeleteButton hideText size="small" recordItemId={record.id} />
              )} */}
                                        {record.isUploaded && (
                                            <a
                                                href={record.originalFileUrl}
                                                download={record.id}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <Button
                                                    size='small'
                                                    icon={<FileOutlined />}
                                                    title={t(
                                                        'projects.buttons.downloadSourceImage'
                                                    )}
                                                />
                                            </a>
                                        )}
                                        {record.isUploaded && record.processingResult && (
                                            <a
                                                href={record.processedFileUrl}
                                                download={record.id}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <Button
                                                    size='small'
                                                    icon={<FileMarkdownOutlined />}
                                                    title={t(
                                                        'projects.buttons.downloadProcessedImage'
                                                    )}
                                                />
                                            </a>
                                        )}
                                    </Space>
                                )}
                            />
                        </Table>
                    </Col>
                </Row>
            </Show>
            {showPreprocessing && (
                <Preprocesing
                    image={filesData && filesData[0]?.originalFileUrl}
                    onClose={() => setShowPreprocessing(false)}
                />
            )}
            {recordToShow && (
                <CompareSliderModal
                    show={showCompareSliderModal}
                    notFound={!recordToShow.fileName}
                    notFoundText={'File with requested id not found in the project.'}
                    fileName={recordToShow.fileName}
                    projectName={record?.name || 'Untitled'}
                    itemOne={recordToShow.originalFileUrl}
                    itemTwo={recordToShow.processedFileUrl}
                    defects={recordToShow.processingResult?.defects || []}
                    defectsMapping={defectsMapping}
                    onClose={handleCloseCompareSliderModal}
                />
            )}
            {
                <Modal
                    title={t('projects.modal.warning')}
                    open={processingComfirmationModalConfig.open}
                    onOk={handleProcessingConfirmModalOk}
                    confirmLoading={processingComfirmationModalConfig.loading}
                    onCancel={handleProcessingConfirmModalCancel}
                >
                    <p>{processingComfirmationModalConfig.content}</p>
                </Modal>
            }
            {isDownloadModalOpen && (
                <Modal
                    title={t('projects.modal.downloadImages.title')}
                    visible={isDownloadModalOpen}
                    onOk={handleDownloadImages}
                    onCancel={handleDownloadModalClose}
                >
                    <Typography.Paragraph>
                        {t('projects.modal.downloadImages.content1')}
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        {t('projects.modal.downloadImages.content2')}
                    </Typography.Paragraph>
                </Modal>
            )}
        </>
    );
};
