import { useEffect, useState } from 'react';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DroppableProps,
    DropResult,
} from 'react-beautiful-dnd';
import { Button, List, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { getProjectBorderColor, getProjectStatusColor } from 'utils';
import { HttpError, useList, useNotification, useTranslate } from '@refinedev/core';
import { ProcessingStatus } from 'interfaces';
import { axiosInstance } from 'App';
import * as constants from '../../constants';
import './styles.less';

dayjs.extend(relativeTime);

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
    const [enabled, setEnabled] = useState<boolean>(false);

    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true));

        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);

    if (!enabled) {
        return null;
    }

    return <Droppable {...props}>{children}</Droppable>;
};

const { Text } = Typography;

interface IQueueItem {
    id: string;
    projectId: string;
    order: number;
    createdAt: string;
    updatedAt: string;
    loading?: boolean;
    project: {
        id: string;
        name: string;
        description: null;
        processingStatus: ProcessingStatus;
        createdAt: string;
        updatedAt: string;
        processingStartedAt: string;
        processingFinishedAt: string;
        deletedAt: null;
        factoryId: string;
        userId: string;
        deleted: boolean;
    };
}

const reorder = (list: IQueueItem[], startIndex: number, endIndex: number) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);

    return list;
};

function ProjectsQueueTimeLine() {
    const t = useTranslate();
    const notification = useNotification();

    const { data, isLoading } = useList<IQueueItem, HttpError>({
        resource: 'projects-processing-queue',
        sorters: [{ field: 'order', order: 'asc' }],
    });

    const [queueList, setQueueList] = useState<IQueueItem[]>([]);

    const handleDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const initialQueueList = queueList;
        const reoderedQueueList = reorder(queueList, result.source.index, result.destination.index);
        const order = result.destination.index + 1;
        const undoQueueList = () => setQueueList(initialQueueList);

        setQueueList(reoderedQueueList);

        axiosInstance
            .patch(`${constants.API_ROOT}/projects-processing-queue/${result.draggableId}`, {
                order,
            })
            .then((response) => {
                if (response.data) {
                    notification.open?.({
                        message: t('notifications.success'),
                        type: 'success',
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                if (error.response.data.statusCode === 400) {
                    notification.open?.({
                        message: `${t('notifications.queueOrderError')} ${order}`,
                        type: 'error',
                    });
                } else {
                    undoQueueList();
                    notification.open?.({
                        message: t('notifications.requestError'),
                        type: 'error',
                    });
                }
            });
    };

    const handleDeleteProjectFromQueue = (id: string) => {
        const initialQueueList = queueList;
        const filteredQueueList = queueList.filter((el) => el.id !== id);
        const queueListWithLoadingStatus = queueList.map((el) =>
            el.id === id ? { ...el, loading: true } : el
        );

        setQueueList(queueListWithLoadingStatus);

        axiosInstance
            .delete(`${constants.API_ROOT}/projects-processing-queue/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    setQueueList(filteredQueueList);
                    notification.open?.({
                        message: t('notifications.success'),
                        type: 'success',
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                setQueueList(initialQueueList);
                notification.open?.({
                    message: t('notifications.requestError'),
                    type: 'error',
                });
            });
    };

    useEffect(() => {
        const queueItems = data?.data ?? [];
        setQueueList(queueItems.map((el) => ({ ...el, loading: false })));
    }, [data?.data]);

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <StrictModeDroppable droppableId='droppable'>
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ height: 'calc(100vh - 217px)', overflow: 'auto' }}
                    >
                        <List loading={isLoading}>
                            {queueList.map(({ project, createdAt, id, loading }, index) => (
                                <Draggable key={id} draggableId={id} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <List.Item
                                                actions={[
                                                    <Button
                                                        loading={loading}
                                                        disabled={loading}
                                                        type='text'
                                                        onClick={() =>
                                                            handleDeleteProjectFromQueue(id)
                                                        }
                                                    >
                                                        {t('buttons.delete')}
                                                    </Button>,
                                                ]}
                                                key={project.id}
                                                style={{
                                                    cursor: 'pointer',
                                                    border: `0.25px solid ${getProjectBorderColor(
                                                        project.processingStatus
                                                    )}`,
                                                    padding: 0,
                                                    backgroundColor: getProjectStatusColor(
                                                        project.processingStatus
                                                    ),
                                                    borderRadius: 7.5,
                                                }}
                                                color={getProjectStatusColor(
                                                    project.processingStatus
                                                )}
                                            >
                                                <div className='queue-list__item'>
                                                    <Tooltip
                                                        overlayInnerStyle={{ color: '#626262' }}
                                                        color='rgba(255, 255, 255, 0.3)'
                                                        placement='topLeft'
                                                        title={dayjs(createdAt).format(
                                                            'DD/MM/YY HH:mm:ss'
                                                        )}
                                                    >
                                                        <Text italic className='createdAt'>
                                                            {dayjs(createdAt).fromNow()}
                                                        </Text>
                                                    </Tooltip>
                                                    <Text>
                                                        {t(
                                                            `enum.processingStatuses.${project.processingStatus}`
                                                        )}
                                                    </Text>
                                                    <Text strong>#{project.name}</Text>
                                                </div>
                                            </List.Item>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    </div>
                )}
            </StrictModeDroppable>
        </DragDropContext>
    );
}

export default ProjectsQueueTimeLine;
