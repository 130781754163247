import { Edit } from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons';

import { Button, Input, Card, Form, Layout as AntdLayout, Row, Col, Typography } from 'antd';
import { useNavigation, useNotification, useTranslate } from '@refinedev/core';
import { axiosInstance } from 'App';
import React, { useRef } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import * as constants from '../../constants';
import '../login/styles.less';

const { Title } = Typography;

const ChangePasswordForm = ({
    onFormSubmit,
    formRef,
    t,
}: {
    onFormSubmit: (values: any) => Promise<void>;
    formRef: React.MutableRefObject<any>;
    t: any;
}) => {
    return (
        <Form onFinish={onFormSubmit} ref={formRef} layout='vertical'>
            <Form.Item
                label={t('users.fields.password')}
                name='password'
                rules={[
                    {
                        required: true,
                    },
                    {
                        min: 6,
                    },
                ]}
            >
                <Input.Password autoComplete='off' />
            </Form.Item>
            <Form.Item
                label={t('users.fields.passwordConfirmation')}
                name='passwordConfirmation'
                rules={[
                    {
                        required: true,
                    },
                    {
                        min: 6,
                    },
                ]}
            >
                <Input.Password autoComplete='off' />
            </Form.Item>
        </Form>
    );
};

export const ChangePasswordPage: React.FC = () => {
    const formRef = useRef<any>(null);

    const [isLoading, setLoading] = React.useState<boolean>(false);
    const navigate = useNavigation();

    const notification = useNotification();
    const t = useTranslate();

    const isAuthenticated = localStorage.getItem('sp-access-token');

    const search = useLocation().search;

    const email = new URLSearchParams(search).get('email');
    const token = new URLSearchParams(search).get('token');

    const handleSubmitClick = () => {
        formRef.current?.submit();
    };

    const handleFormSubmit = async (values: any) => {
        setLoading(true);

        const password = values.password;
        const passwordConfirmation = values.passwordConfirmation;

        if (!password || !passwordConfirmation) {
            notification.open?.({
                message: t('notifications.missingRequiredParamsError'),
                type: 'error',
            });
            setLoading(false);
            return;
        }

        if (password !== passwordConfirmation) {
            notification.open?.({
                message: t('users.notifications.passwordsDoesNotMatch'),
                type: 'error',
            });
            setLoading(false);
            return;
        }

        if (!email || !token) {
            notification.open?.({
                message: t('notifications.missingRequiredParamsError'),
                type: 'error',
            });
            setLoading(false);
            return;
        } else {
            axiosInstance
                .post(`${constants.API_ROOT}/auth/repair-password`, {
                    email,
                    password,
                    passwordConfirmation,
                    token,
                })
                .then((response) => {
                    if (response.data) {
                        notification.open?.({
                            message: t('notifications.success'),
                            type: 'success',
                        });
                        navigate.push('/');
                    }
                })
                .catch((error) => {
                    console.error(error);
                    notification.open?.({
                        message: t('notifications.requestError'),
                        type: 'error',
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    if (!email || !token) {
        return <Navigate to='/' />;
    }

    return isAuthenticated ? (
        <Edit
            title='Change password'
            headerProps={{
                backIcon: false,
                style: {
                    color: 'white',
                },
            }}
            headerButtons={() => (
                <Button type='default' onClick={() => navigate.push('/')}>
                    Go back
                </Button>
            )}
            footerButtons={() => (
                <Button
                    disabled={isLoading}
                    loading={isLoading}
                    icon={<Icons.SaveOutlined />}
                    onClick={handleSubmitClick}
                    type='primary'
                >
                    {t('buttons.save')}
                </Button>
            )}
        >
            <ChangePasswordForm onFormSubmit={handleFormSubmit} formRef={formRef} t={t} />
        </Edit>
    ) : (
        <AntdLayout className='layout'>
            <Row
                justify='center'
                align='middle'
                style={{
                    height: '100vh',
                }}
            >
                <Col xs={22}>
                    <div className='container'>
                        <Card
                            title={
                                <Title level={3} className='title'>
                                    Change password
                                </Title>
                            }
                            headStyle={{ borderBottom: 0 }}
                        >
                            <ChangePasswordForm
                                t={t}
                                formRef={formRef}
                                onFormSubmit={handleFormSubmit}
                            />
                            <Button
                                block
                                size='large'
                                disabled={isLoading}
                                loading={isLoading}
                                icon={<Icons.SaveOutlined />}
                                onClick={handleSubmitClick}
                                type='primary'
                            >
                                {t('buttons.save')}
                            </Button>
                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
