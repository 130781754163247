import * as Icons from '@ant-design/icons';

import { ToggleContainer } from './styled';

const { LeftOutlined } = Icons;

export const OffLayoutArea = () => {
    return (
        <ToggleContainer>
            <LeftOutlined />
            {/* <a href="https://example.refine.dev">
                Switch to <br />
                <strong>CLIENT APP</strong>
            </a> */}
        </ToggleContainer>
    );
};
