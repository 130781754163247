import { FC } from 'react';
import { useTranslate, IResourceComponentsProps, useCustom } from '@refinedev/core';

import {
    List,
    TextField,
    useTable,
    getDefaultSortOrder,
    DateField,
    BooleanField,
    ShowButton,
} from '@refinedev/antd';

import { Table, Space } from 'antd';
import { IArchive, IProject } from 'interfaces';
import { API_ROOT, DATE_FORMAT } from '../../constants';

export const ArchivesList: FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { tableProps, sorters: sorter } = useTable<IArchive>({
        sorters: {
            initial: [
                {
                    field: 'id',
                    order: 'desc',
                },
            ],
        },
    });

    const { data: projectsData, isLoading } =
        useCustom<IProject[]>({
            url: `${API_ROOT}/projects`,
            method: 'get',
        }) || [];

    const projects = projectsData?.data ?? [];

    return (
        <List>
            <Table {...tableProps} rowKey='id'>
                <Table.Column
                    key='projectId'
                    title={t('archives.fields.project')}
                    render={(value) => {
                        if (isLoading) {
                            return <TextField value={t('loading')} />;
                        }
                        const proj = projects?.find((item) => item.id === value.projectId);

                        return <TextField value={`${proj?.name} (${proj?.id})`} />;
                    }}
                />
                <Table.Column
                    dataIndex='id'
                    key='id'
                    title={t('archives.fields.id')}
                    render={(value) => <TextField value={value} />}
                    defaultSortOrder={getDefaultSortOrder('title', sorter)}
                    sorter
                />
                <Table.Column
                    dataIndex='archiveType'
                    key='archiveType'
                    title={t('archives.fields.archiveType')}
                    render={(value) => <TextField value={value} />}
                />
                <Table.Column
                    dataIndex='isCompleted'
                    title={t('archives.fields.isCompleted')}
                    render={(value) => {
                        return <BooleanField value={value} />;
                    }}
                />
                <Table.Column
                    dataIndex='createdAt'
                    key='createdAt'
                    title={t('archives.fields.createdAt')}
                    render={(value) =>
                        value ? <DateField value={value ? value : ''} format={DATE_FORMAT} /> : '-'
                    }
                    defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
                    sorter
                />
                <Table.Column
                    dataIndex='completedAt'
                    key='completedAt'
                    title={t('archives.fields.completedAt')}
                    render={(value) =>
                        value ? <DateField value={value ? value : ''} format={DATE_FORMAT} /> : '-'
                    }
                    defaultSortOrder={getDefaultSortOrder('completedAt', sorter)}
                    sorter
                />
                {/* <Table.Column
          dataIndex={["category", "id"]}
          title={t("posts.fields.category.title")}
          render={(value) => {
            if (isLoading) {
              return <TextField value={t("loading")} />;
            }

            return (
              <TextField
                value={
                  categoriesData?.data.find((item) => item.id === value)?.title
                }
              />
            );
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder={t("posts.fields.category.filter.placeholder")}
                {...categorySelectProps}
              />
            </FilterDropdown>
          )}
        /> */}
                <Table.Column<IArchive>
                    title={t('table.actions')}
                    dataIndex='actions'
                    render={(_, record) => (
                        <Space>
                            <ShowButton hideText size='small' recordItemId={record.id} />
                            {/*{permissionsData?.includes(Roles.ADMIN) && (*/}
                            {/*    <EditButton hideText size="small" recordItemId={record.id}/>*/}
                            {/*)}*/}
                            {/*{permissionsData?.includes(Roles.ADMIN) && (*/}
                            {/*    <DeleteButton hideText size="small" recordItemId={record.id}/>*/}
                            {/*)}*/}
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
