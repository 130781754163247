import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Rect } from 'react-konva';
import { Rectangle } from '..';
import { v4 as uuidv4 } from 'uuid';
import { theme } from 'antd';

const initialData = { x: 0, y: 0, width: 0, height: 0, id: '' };
const { useToken } = theme;

interface SelectorProps {
    stageRef: MutableRefObject<any>;
    imageRef: MutableRefObject<any>;
    isDrawActive: boolean;
    onAddSection: (data: Rectangle) => void;
    onDisableDraw: () => void;
}

const Selector = ({
    stageRef,
    imageRef,
    isDrawActive,
    onAddSection: addSection,
    onDisableDraw: disableDraw,
}: SelectorProps) => {
    const { token } = useToken();
    const [points, setPoints] = useState(initialData);
    const [startPoints, setStartPoints] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const dataRef = useRef(points);

    const [isDraw, setIsDraw] = useState<boolean>(false);

    useEffect(() => {
        const stage = stageRef.current;
        const image = imageRef.current;

        const handleMouseDown = (e: any) => {
            const isBunDrawing =
                e.offsetX < image.getAbsolutePosition().x ||
                e.offsetX > image.getAbsolutePosition().x + image.width() * stage.scale().x ||
                e.offsetY < image.getAbsolutePosition().y ||
                e.offsetY > image.getAbsolutePosition().y + image.height() * stage.scale().y;

            if (isBunDrawing) {
                return;
            }
            if (isDrawActive) {
                setStartPoints({
                    x: (e.offsetX - stage.position().x) / stage.scale().x,
                    y: (e.offsetY - stage.position().y) / stage.scale().y,
                });

                setIsDraw(true);
            }
        };

        const handleMouseMove = (e: any) => {
            const offsetX = (e.offsetX - stage.position().x) / stage.scale().x;
            const offsetY = (e.offsetY - stage.position().y) / stage.scale().y;
            const isFreezeDrawing =
                e.offsetX < image.getAbsolutePosition().x ||
                e.offsetX > image.getAbsolutePosition().x + image.width() * stage.scale().x ||
                e.offsetY < image.getAbsolutePosition().y ||
                e.offsetY > image.getAbsolutePosition().y + image.height() * stage.scale().y;

            const calculateRectCoords = (startPoints: { x: number; y: number }) => {
                const id = uuidv4();

                if (startPoints.x < offsetX && startPoints.y < offsetY) {
                    setPoints(() => {
                        const newData = {
                            id,
                            x: startPoints.x,
                            y: startPoints.y,
                            width: Math.abs(startPoints.x - offsetX),
                            height: Math.abs(startPoints.y - offsetY),
                        };
                        dataRef.current = newData;
                        return newData;
                    });
                } else if (startPoints.x < offsetX && startPoints.y > offsetY) {
                    setPoints(() => {
                        const newData = {
                            id,
                            x: startPoints.x,
                            y: offsetY,
                            width: Math.abs(points.x - offsetX),
                            height: Math.abs(startPoints.y - offsetY),
                        };
                        dataRef.current = newData;
                        return newData;
                    });
                } else if (startPoints.x > offsetX && startPoints.y < offsetY) {
                    setPoints(() => {
                        const newData = {
                            id,
                            x: offsetX,
                            y: startPoints.y,
                            width: Math.abs(startPoints.x - offsetX),
                            height: Math.abs(points.y - offsetY),
                        };
                        dataRef.current = newData;
                        return newData;
                    });
                } else if (startPoints.x > offsetX && startPoints.y > offsetY) {
                    setPoints(() => {
                        const newData = {
                            id,
                            x: offsetX,
                            y: offsetY,
                            width: Math.abs(startPoints.x - offsetX),
                            height: Math.abs(startPoints.y - offsetY),
                        };
                        dataRef.current = newData;
                        return newData;
                    });
                } else {
                    setPoints((data) => data);
                }
            };

            if (isDraw && isDrawActive) {
                if (isFreezeDrawing) {
                    return;
                }
                calculateRectCoords(startPoints);
            }
        };

        const handleMouseUp = () => {
            if (isDraw && isDrawActive) {
                setIsDraw(false);
                addSection(points);
                setPoints(initialData);
                disableDraw();
            }
        };

        if (stage) {
            stage.addEventListener('mousedown', handleMouseDown);
            stage.addEventListener('mouseup', handleMouseUp);
            stage.addEventListener('mousemove', handleMouseMove);

            return () => {
                if (stage) {
                    stage.removeEventListener('mousedown', handleMouseDown);
                    stage.removeEventListener('mouseup', handleMouseUp);
                    stage.removeEventListener('mousemove', handleMouseMove);
                }
            };
        }
    }, [stageRef, isDraw, imageRef, startPoints, points, addSection, disableDraw, isDrawActive]);

    return (
        <Rect
            {...points}
            stroke={token.colorPrimary}
            fill={'rgba(25, 118, 210, 0.25)'}
            strokeWidth={1}
        />
    );
};

export default Selector;
